//  ======================================================
//  STRUCTURE.SCSS
//  Styles for site Wrappers
//  ======================================================

// -----------------------------------------------------
// SKIP CONTENT BUTTON
// -----------------------------------------------------

#skipContentWrapper {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
}

#skipContentLink {
	display: block;
	width: 1px;
	height: 1px;
	overflow: hidden;

	&:hover, &:visited {
		display: block;
		width: 1px;
		height: 1px;
		overflow: hidden;
	}

	&:active, &:focus {
		position: relative;
		width: auto;
		height: auto;
		font-size: 15px;
		z-index: 10000;
		text-decoration: underline;
		padding: 20px;
		background: $brand-one;
		color: #FFF;
	}
}


// -----------------------------------------------------
// WRAPPERS
// -----------------------------------------------------

#uber {
	width: 100%;
	margin: 0 auto;
	background: #fff;
}

#uberMain {
	width: 100%;
	margin: 0 auto;
	padding: 0 0 30px 0;
	overflow: hidden;
	position: relative;
	z-index: 1;

	.homepage & {
		padding-bottom: 0;
	}
}

#main{
	display: flex;
	flex-direction: column;
	overflow: hidden;
	margin: 0 auto;
	max-width: $container-max-width;
	padding: 0 30px;
	position: relative;
	width: 100%;
	z-index: 1;

	@media (min-width: $screen-sm){
		padding: 0 4%;
	}

	@media (min-width: $screen-md) {
		flex-direction: row;
		width: 94%;
		padding: 0;
	}
}

.ic-container-fluid {
	width: 94%;
	max-width: $container-max-width;
	margin: 0 auto;
	padding: 0;
	position: relative;
	z-index: 1;
}


// -----------------------------------------------------
// CONTENT AREAS
// -----------------------------------------------------

#contentInt {
	background: #fff;
	overflow: hidden;
	width: 100%;
	padding: 0;
	min-height: 400px;
	order: 1;

	.wide & {
		width: 100%;
		padding-right: 0;
	}

	@media (min-width: $screen-md) {
		padding: 0 30px 0 0;
		width: $content-width;

		.subNav-Left & {
			padding: 0 0 0 30px;
		}
	}
}

#printAreaContent {
	&::before{
		clear: left;
		content: "";
		display: table;
	}
}

// -----------------------------------------------------
// TOGGLE CONTENT (for testing only)
// -----------------------------------------------------

.toggleContent {
	display: none;
	padding: 10px;
	background: $gray-background;
	font-size: .9em;
	margin-bottom: 20px;

	@media (min-width: $screen-sm) {
		display: block;
	}
}