//  ======================================================
//  TOPNAV.SCSS
//  top navigation styles
//  ======================================================

$topnav-font-size:				rem(15px) !default;
$topnav-line-height:			1.25 !default;
$topnav-font-weight:			600 !default;
$topnav-link-color:				white !default;
$topnav-link-decoration:		none !default;
$topnav-link-hover-decoration:	underline !default;

#topNavContainer {
	display: none;
	width: 100%;
	white-space: nowrap;
	text-overflow: ellipsis;
	background-color: $brand-one;

	@media (min-width: $screen-lg) {
		display: flex;
		justify-content: flex-end;
	}
}

.topNavContainerInner {
	display: flex;
	justify-content: flex-end;
	max-width: 100%;

	@media (min-width: $screen-xl) {
		// max-width: calc(100% - #{$header-left-width});
	}
}

header {
	.socialLinks {
		position: relative;
		display: inline-flex;
		align-items: center;
		width: 100%;
		line-height: 1;
		padding: 10px 1rem 10px;
		border-top: 1px solid $gray-border;
		background-color: $brand-one;

		@media (min-width: $screen-lg) {
			width: auto;
			margin: 0;
			padding: 0 16px;
			border-top: 0;
			background-color: transparent;

			&:before {
				position: absolute;
				top: 50%;
				left: 0;
				width: 1px;
				height: 21px;
				background-color: rgba(white, .25);
				transform: translateY(-50%);
				content: '';
			}
		}

		ul {
			list-style: none;
			margin: 0;
			padding:0;


			li {
				display: inline-block;
				margin: 0 4px;

				a {
					@include socialIcons('circle', white, $brand-one, 14px);
					display: block;
					width: 22px;
					height: 22px;

					&:after {
						padding: 4px!important;
					}

					&:hover,
					&:focus {
						@include socialIcons('cicle', $brand-two, white, 14px);

					}
				}
			}
		}
	}
}

#topNav {
	display: flex;
	font-size: $topnav-font-size;
	line-height: 1;
	text-align: right;
	overflow: hidden;

	a, a:link, a:visited {
		color: $topnav-link-color;
	}

	a {
		display: inline-block;
		padding: 14px 12px;
		min-width: 1px;
		height: 100%;
		font-weight: $topnav-font-weight;
		line-height: $topnav-line-height;
		text-decoration: $topnav-link-decoration;
		text-overflow: ellipsis;
		overflow: hidden;

		&:hover, &:active, &:focus {
			text-decoration: $topnav-link-hover-decoration;
		}
	}
}
