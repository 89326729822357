//  ======================================================
//  MAINNAV.SCSS
//  styles for main menu & mobile menu
//  ======================================================

$nav-font-size:					rem(18px) !default;
$nav-line-height:				1.33333 !default;
$nav-font-weight:				600 !default;
$nav-font-family:				inherit !default;
$nav-item-text-color:		    $gray-dark !default;
$nav-item-text-align:		    left !default;
$nav-item-spacing:				1px !default; //gap between items
$nav-item-spacing-color:		transparent !default; //color of divider between items
$nav-item-bg:					transparent !default;
$nav-item-hover-bg:				transparent !default;
$nav-item-hover-color:			$nav-item-text-color !default;

$nav-dropdown-bg:						white !default;
$nav-dropdown-border:					#D9DCD6 !default;
$nav-dropdown-padding:					25px !default;
$nav-dropdown-border-radius:		    3px !default;

$nav-dropdown-col-count:				4 !default; // number of columns in dropdown
$nav-dropdown-col-spacing:				25px !default; // space between columns

$nav-dropdown-item-padding:				16px 0 !default;
$nav-dropdown-item-divider:				$gray-border !default;
$nav-dropdown-item-font-size:			rem(18px) !default;
$nav-dropdown-item-font-weight:			normal !default;
$nav-dropdown-item-text-color:			$brand-one !default;
$nav-dropdown-item-decoration:			none !default;
$nav-dropdown-item-hover-decoration: 	underline !default;
$nav-dropdown-item-hover-bg: 			transparent !default;

$mobile-dropdown-bg:					$brand-one !default;

$mobile-dropdown-item-padding:			10px !default;
$mobile-dropdown-item-divider:			fade-out(white, .9) !default;
$mobile-dropdown-item-text-color:		white !default;
$mobile-dropdown-item-text-align:		center !default;
$mobile-dropdown-item-font-size:		$font-size-large !default;
$mobile-dropdown-item-font-weight:		normal !default;
$mobile-dropdown-item-active-bg:		darken($mobile-dropdown-bg, 5%) !default;

$expander-width: 54px !default;

%mainNavHover {
	color: $nav-item-hover-color;
	background: $nav-item-hover-bg;

	&:after {
		display: block;
	}
}

#mainNav {
	position: absolute;
	display: none;
	top: 100%;
	margin-right: -3%; //subtract header padding
	margin-left: -3%;
	width: 100%;
	background-color: white;
	border-bottom: 1px solid $nav-dropdown-item-divider;

	@media (min-width: $screen-lg) {
		position: static;
		display: block !important;
		margin: 0;
		top: auto;
		border-bottom: 0;
	}
}

#nav {
	margin: 0;
	padding-left: 0;
	width: 100%;
	font-size: 0;
	border-top: 1px solid $nav-dropdown-border;

	@media (min-width: $screen-lg) {
		display: flex;
		justify-content: flex-end;
		width: 100%;
		border-top: 0;
	}

	> li {
		display: block;
		font-size: 1rem;
		text-overflow: ellipsis;
		overflow: hidden;

		@media (max-width: $screen-lg - 1) {
			flex-flow: row wrap;
			display: flex;
			background-color: $nav-item-bg;
		}

		@media (min-width: $screen-lg) {
			display: table-cell;
			vertical-align: middle;
		}

		&.sfHover {
			border-bottom: 0;
		}
	}

	&.nav--click { // click nav specific, see mainNav.js
	}

	&.nav--hover { // hover nav specific, see mainNav.js
		.dropdownViewAll {
			display: none;
		}
	}
}

a.mainNavItem,
.topNavMobile > a,
#browseAloudContainer a,
#browseAloudContainer button {
	position: relative;
	display: block;
	padding: .75rem 1rem;
	width: 100%;
	height: 100%;
	font-family: $nav-font-family;
	font-size: 1rem;
	font-weight: $nav-font-weight;
	line-height: $nav-line-height;
	text-align: $nav-item-text-align;
	text-overflow: ellipsis;
	color: $nav-item-text-color;
	background: $nav-item-bg;
	border: 0;
	overflow: hidden;

	@media (max-width: $screen-lg - 1) {
		flex-grow: 1;
		width: calc(100% - #{$expander-width});
		border-bottom: 1px solid $nav-dropdown-item-divider;

		.noDropdown & {
			width: 100%;
		}
	}

	@media (min-width: $screen-lg) {
		padding: 16px 1rem 23px;
	}

	@media (min-width: 1600px) {
		font-size: $nav-font-size;
	}

	&:after {
		position: absolute;
		left: 0;
		bottom: 0;
		display: none;
		width: 5px;
		height: 100%;
		background-color: $brand-three;
		content: '';

		@media (min-width: $screen-lg) {
			width: 100%;
			height: 5px;
		}
	}

	&:focus, &:hover {
		text-decoration: none;
		@extend %mainNavHover;

		&:after {
			background-color: $brand-two;
		}
	}

	.current & {
		@extend %mainNavHover;
	}
	.sfHover & {
		@extend %mainNavHover;

		&:after {
			background-color: $brand-two;
		}
	}

	#mainNav:hover .sfHover &,
	#mainNav:focus .sfHover & {
		@extend %mainNavHover;
	}
}

a.mainNavItem {
	@media (min-width: $screen-lg) {
		padding-right: 40px;

		&:before {
			@include icomoon();
			content: $icon-chevron-thick-down;
			position: absolute;
			top: 50%;
			right: 18px;
			font-size: 10px;
			transform: translateY(-50%);
		}
	}

	&[aria-expanded="true"] {
		@media (min-width: $screen-lg) {
			&::before {
				transform: scaleY(-1) translateY(50%);
			}
		}
	}
}

// combo box arrow for main nav
.mainNavItem_expander {
	cursor: pointer;
	width: $expander-width !important;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: $gray-lighter;
	border: 0;
	border-left: 1px solid $nav-dropdown-border;
	border-bottom: 1px solid $nav-dropdown-border;

	position: relative;
	-moz-appearance: none;
	-ms-appearance: none;
	-webkit-appearance: none;
	appearance: none;


	@media (min-width: $screen-lg){
		display: none;
		border-bottom: 0!important;
	}

	.icon {
		width: 21px;
		height: 21px;
		display: block;
		cursor: pointer;
		position: relative;

		&::before,
		&::after {
			content: "";
			width: 16px;
			height: 2px;
			background: #000;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: auto;
			transition: transform 350ms ease;
		}

		&::before {
			transform: rotate(180deg);
		}

		&::after {
			transform: rotate(90deg);
			transform-origin: center;
		}
	}

	&[aria-expanded="true"] {
		.icon {
			&::before,
			&::after {
				transform: rotate(0);
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN
// -----------------------------------------------------

#mainNav {
	.dropDownContainer {
		display: none;
		position: absolute;
		z-index: 900;
		left: 50%;
		top: 100%;
		width: 100%;
		max-width: 1522px;
		padding: $nav-dropdown-padding;
		border: 1px solid $nav-dropdown-border;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		background: $nav-dropdown-bg;
		border-radius: 0 0 $nav-dropdown-border-radius $nav-dropdown-border-radius;
		text-align: left;
		transform: translateX(-50%);

		@media (max-width: $screen-lg - 1) {
			flex: 1 1 auto;
			width: 100%;
			min-width: 0;
			border: 0;
			padding: 0;
			position: static;
			transform: translateX(0);

			.dropdown {
				list-style: none;
				margin: 0;
				padding: 0;
				width: 100%;
				&:first-child {
					margin: 0;
				}
				li {
					display: block;
					width: 100%;
					background: none;
					&:hover, &:focus {
						background: #f5f5f5;
					}
					a {
						display: block;
						width: 100%;
						padding: 8px 16px;
						font-size: $nav-dropdown-item-font-size;
						font-weight: $nav-dropdown-item-font-weight;
						line-height: 1.333;
						text-decoration: $nav-dropdown-item-decoration;
						color: $nav-dropdown-item-text-color;
						overflow: hidden;

						.contrast-mode & {
							color: black;
						}
					}
					&:last-child {
						border-bottom: 1px solid $nav-dropdown-item-divider;
					}
				}
				&.dropDownRight {
					display: none;
				}
				&:nth-child(2) {
					li:last-child {
						@media(min-width: $screen-lg){
							border-bottom: none;
						}
					}
				}
			}
		}
	}

	.dropdown {
		float: left;
		width: 100%/$nav-dropdown-col-count;
		padding: 0 $nav-dropdown-col-spacing;
		list-style: none;
		overflow: hidden;
		&:first-child {
			margin: 0;
		}
		li {
			float: left;
			width: 100%;
			line-height: 1.375;
			background: none;
			a {
				display: block;
				width: 100%;
				color: $nav-dropdown-item-text-color;
				font-size: $nav-dropdown-item-font-size;
				font-weight: $nav-dropdown-item-font-weight;
				padding: $nav-dropdown-item-padding;
				text-decoration: $nav-dropdown-item-decoration;
				border-top: 1px solid $nav-dropdown-item-divider;
				overflow: hidden;
				&:hover, &:active, &:focus {
					text-decoration: $nav-dropdown-item-hover-decoration;
					background-color: $nav-dropdown-item-hover-bg;
				}
				&.viewMore {
					border: 1px solid $nav-dropdown-item-divider;
					padding: $nav-dropdown-item-padding;
					padding-left: 15px;
					padding-right: 15px;
					font-weight: 600;
					position: relative;

					&:before {
						@include icomoon();
						content: $icon-chevron-thick-right;
						position: absolute;
						font-size: 20px;
						display: flex;
						align-items: center;
						top: 0;
						bottom: 0;
						right: 10px;
						color: #000;
						pointer-events: none;
					}
				}
			}
			&:first-child a {
				border: none;
			}
		}
	}

	.dropdownViewAll {
		display: block; // hidden in concept
		margin: 0 $nav-dropdown-col-spacing;
		text-align: right;

		@media (max-width: $screen-lg - 1) {
			display: none;
		}

		a {
			display: block;
			padding: 5px 0;
			font-size: rem(16px);
			font-weight: $nav-dropdown-item-font-weight;
			text-decoration: $nav-dropdown-item-decoration;
			color: $nav-dropdown-item-text-color;

			&:active,
			&:focus,
			&:hover {
				text-decoration: $nav-dropdown-item-hover-decoration;
				background-color: $nav-dropdown-item-hover-bg;
			}
		}
	}
}

// -----------------------------------------------------
// MAIN NAV DROP DOWN IMAGE RIGHT
// -----------------------------------------------------

#mainNav .dropdown a.dropDownRightInner {
	display: block;
	float: left;
	width: 100%;
	margin: 8px 0;
	background: #fff;
	border: 0;
	position: relative;

	&:first-of-type {
		margin-top: 0;
	}

	&:before {
		position: absolute;
		content: "";
		bottom: 0;
		right: 0;
		left: 0;
		width: 100%;
		height: 75px;
		background: linear-gradient(to bottom, rgba(black,0) 0,rgba(black,1) 100%);
		opacity: .75;
	}
	img {
		width: 100%;
		height: auto;
	}
	.dropDownRightOverlay {
		position: absolute;
		right: 0;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		padding: 15px 19px;
		color: #fff;
		font-size: em(21px);
		line-height: 1.3;
		font-weight: 600;
		text-align: left;
	}
	&:hover .dropDownRightOverlay, &:focus .dropDownRightOverlay{
		text-decoration: underline;
	}
}

