// Burger Menu parts
//
// (---) top    -> &::before
// [---] middle -> &
// (---) bottom -> &::after

// Usage
// @include burger(width, height, border, border-radius, background, padding, bar-height, gutter, color, bar-border-radius, transition-duration);
// @include burger(45px, 45px, 1px solid #ccc, 2px, #f3f3f3, 5px, 4px, 5px, #222, 5px, 0.3s);

// Calc Width Function
@function calc-TriggerTextWidth($value1, $value2) {
    @return $value1 * $value2;
}


// Burger Menu
@mixin burger($width: 30px, $height: 30px, $border: 1px solid #ccc, $border-radius: 2px, $background: #f2f2f2, $padding: 5px, $bar-height: 5px, $gutter: 3px, $color: #000, $bar-border-radius: 0, $transition-duration: .3s) {

    //set global variables
    $burger-height: $bar-height !global;
    $burger-gutter: $gutter !global;

    //reset button styles
    border: none;
    padding: 0;
    margin: 0;
    text-decoration: none;
    background: none;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;

    //set burger menu styles
    position: relative;
    display: inline-block;
    width: $width;
    height: $height;
    padding: 0 $padding;
    border: $border;
    @if $border-radius != 0 {
        border-radius: $border-radius;
    }
    background: $background;
    user-select: none;

    span:first-of-type {
        position: relative;
        display: block;
        margin-top: $bar-height + $gutter;
        margin-bottom: $bar-height + $gutter;
        user-select: none;
        // 1. Fixes jagged edges in Firefox, see issue #10.
        &, &::before, &::after {
            display: block;
            width: 100%;
            height: $bar-height;
            background-color: $color;
            outline: 1px solid transparent; // 1
            @if $bar-border-radius != 0 {
                border-radius: $bar-border-radius;
            }
            transition-property: background-color, transform;
            transition-duration: $transition-duration;
        }

        &::before, &::after {
            position: absolute;
            content: "";
        }

        &::before {
            top: -($bar-height + $gutter);
        }

        &::after {
            top: $bar-height + $gutter;
        }
    }

    //text span
    span.triggerText {
        position: relative;
        display: block;
        width: calc(100% + #{calc-TriggerTextWidth($padding, 2)});
        text-align: center;
        text-transform: uppercase;
        font-size: rem(13px);
        font-weight: bold;
        margin-top: 15px;
        margin-bottom: -7.5px;
        margin-left: -$padding;

        &:empty {
            display: none;
        }
    }
}


// Select parts of the burger
@mixin burger-parts {
    span:first-of-type {
        &, &::before, &::after {
            @content;
        }
    }
}

@mixin burger-top {
    span:first-of-type {
        &::before {
            @content;
        }
    }
}

@mixin burger-middle {
    span:first-of-type {
        & {
            @content;
        }
    }
}

@mixin burger-bottom {
    span:first-of-type {
        &::after {
            @content;
        }
    }
}

// Burger to Cross animation
@mixin burger-to-cross($color: auto) {
    span:first-of-type {
        & {
            background-color: transparent;
        }
        @if ($color != auto) {
            &::before, &::after {
                background-color: $color;
            }
        }
        &::before {
            transform: translateY($burger-gutter + $burger-height) rotate(45deg);
        }
        &::after {
            transform: translateY(-($burger-gutter + $burger-height)) rotate(-45deg);
        }
    }
}