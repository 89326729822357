//  ======================================================
//  SETTINGS.SCSS
//  All varibles live here
//  ======================================================

@import 'mixins';

// BREAKPOINTS - values also need to be changed in responsiveScripts.js

// MIN WIDTH VALUES
$screen-xl:                  1400px;
$screen-lg:                  1200px;
$screen-md:                  992px;
$screen-sm:                  768px;
$screen-xs:                  480px;

// COLORS

$gray-base:              	#000;
$gray-darker:            	#1F1A17;
$gray-dark:              	#222;
$gray:                   	#666;
$gray-light:             	#ccc;
$gray-lighter:           	#efefef;

$gray-background:           #F8F8F7; //Update based on concepts
$gray-border:               #D9DCD6; //Update based on concepts

$brand-one:         		#005961; // teal
$brand-two:         		#D84007; // orange
$brand-three:           	#B9E28C; // lime green

$emphasis-primary:          $brand-one;
$emphasis-secondary:        $brand-two;
$emphasis-tertiary:         #D9DCD6; //dark grey

$alert-green: #29D456;
$alert-yellow: #F6C21A;
$alert-blue: #3AD9FF;
$alert-red: #B8180E;

$body-bg:					white; // default background color on body

// TEXT STYLES

$text-color:              	$gray-darker;

$system-fonts: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'Segoe UI', 'lucida grande', 'helvetica neue', helvetica, 'Fira Sans', roboto, noto, 'Droid Sans', cantarell, oxygen, ubuntu, 'franklin gothic medium', 'century gothic', 'Liberation Sans', sans-serif;
$font-family-sans-serif: 'Open Sans', $system-fonts;
$font-family-serif: Georgia, 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;

$font-family-alternate: $font-family-sans-serif;
$font-family-alternate-heading: $font-family-sans-serif;

$font-size-base:          	16px;
$font-weight-base:          normal;
$line-height-base:        	1.625;

$bold-text-font-family:     inherit;
$bold-text-font-weight:     bold;

$headings-font-family:    	inherit;
$headings-font-weight:    	600;
$headings-line-height:    	1.35;
$headings-color:          	inherit;

$font-size-large:         	em(20px); //$font-size-base + 2
$font-size-small:         	em(14px); //$font-size-base - 2

$font-size-h1:            	em(56px);
$text-color-h1: 		  	inherit;
$font-weight-h1: 		  	$headings-font-weight;

$font-size-h2:            	em(35px);
$text-color-h2: 		  	inherit;
$font-weight-h2: 		  	$headings-font-weight;

$font-size-h3:            	em(30px);
$text-color-h3: 		  	inherit;
$font-weight-h3: 		  	$headings-font-weight;

$font-size-h4:            	em(25px);
$text-color-h4: 		  	inherit;
$font-weight-h4: 		  	$headings-font-weight;

$font-size-h5:              em(20px);
$text-color-h5:             inherit;
$font-weight-h5:            $headings-font-weight;

$font-size-h6:              em(18px);
$text-color-h6:             inherit;
$font-weight-h6:            $headings-font-weight;

$font-size-caption:              $font-size-h3;
$text-color-caption:             inherit;
$font-weight-caption:            $bold-text-font-weight;

$font-size-intro:         	$font-size-large;
$text-color-intro: 		  	inherit;
$font-weight-intro:       	inherit;

$link-color:              	#00737D;
$link-font-weight: 	      	bold;
$link-decoration: 	      	none;
$link-hover-color:        	darken($link-color, 5%);
$link-hover-decoration:   	underline;
$link-visited-color:        $link-hover-color;
$link-visited-hover-color:  $link-visited-color;


// NEWS MODULE COLOURS
$news-link-colour:                   $link-color;
$news-back-to-search:                $news-link-colour;
$news-back-to-search-arrow:          $news-back-to-search;
$news-post-date:                     $gray;

$news-subscribe-button-background:   $brand-one;
$news-subscribe-button-foreground:   color-contrast($news-subscribe-button-background);
$news-primary-button-background:     $brand-one;
$news-primary-button-foreground:     color-contrast($news-primary-button-background);
$news-like-button-background:        $gray-lighter;
$news-like-button-foreground:        $news-link-colour;
$news-like-button-heart:             $news-like-button-foreground;
$news-post-comment:                  $news-link-colour;
$news-login-button-background:       $gray-lighter;
$news-login-button-foreground:       $news-link-colour;
$news-post-button-background:        $brand-two;
$news-post-button-foreground:        color-contrast($news-post-button-background);
$news-reply-button-foreground:       $news-link-colour;

$news-date-highlight:                $brand-one;
$news-range-highlight:               #dfecec;

$news-pagination-background:        white;
$news-pagination-foreground:        $news-link-colour;
$news-pagination-active-background: $news-pagination-foreground;
$news-pagination-active-foreground: color-contrast($news-pagination-active-background);

$news-toast-background:             $brand-one;
$news-toast-top:                    100px;

$news-carousel-background:          $brand-one;

// COMPONENTS

$border-radius-base: .25rem;
$border-radius-large: .5rem;
$border-radius-small: .125rem;

$box-shadow-base: 0 .25rem .5rem rgba(black,.2);
$box-shadow-large: 0 .5rem 1rem rgba(black,.2);
$box-shadow-small: 0 .125rem .25rem  rgba(black,.2);

$transition-base: .3s cubic-bezier(0.23, 1, 0.32, 1);

/// TABLES

$table-bg:                      transparent;
$table-border:                  solid 1px $gray-border; //ie solid 1px color
$table-border-radius:           0; // add border-collapse: separate if you want rounded corner

$table-text-color:              inherit;
$table-cell-padding:            11px 22px;
$table-row-bg:                 	#F8F8F7;
$table-altrow-bg:               white;
$table-cell-border-color:       #F8F8F7;

$table-titlerow-bg:             white;
$table-titlerow-padding:        13px 22px;
$table-titlerow-text-align:     left;
$table-titlerow-text-color:     black;
$table-titlerow-font-size:      1em;
$table-titlerow-font-weight:    bold;
$table-titlerow-border-bottom:  solid 5px $brand-two;

// STRUCTURE
$container-width:				94%;
$container-max-width-narrow:	1200px;
$container-max-width:			1400px;
$container-max-width-wide:      1520px;
$container-offset: calc((100vw - #{$container-max-width}) / 2);
$container-wide-offset: calc((100vw - #{$container-max-width-wide}) / 2);
$container-padding: 3%;
$container-mq: 1486px; // val of $container-max-width + ($container-padding * 2)
$container-wide-mq: 1606px; // val of $container-max-width + ($container-padding * 2)
$header-left-width:				200px;

// SEARCH WIDGET
$search-height:					44px;  // .search, searchText, .goButton
$search-bg: #F2F3F1;
$search-border-color:			transparent;
$search-border-radius:			0;
$search-text-color:				$text-color;
$search-text-padding:			3px 10px;
$search-font-size:				inherit;
$search-button-bg:				transparent; // button color

// INTERIOR CONTAINER WIDTHS
$right-col-width:				370px; // #intFloatRight width;
$subnav-width:					$right-col-width;
$content-width:					calc(100% - #{$right-col-width});
$actions-width:					200px;