@mixin theme($property, $transparency: 1) {
	#{$property}: rgba($brand-one, $transparency);
	.theme01 &{
		#{$property}: rgba($theme-01, $transparency);
	}
	.theme02 &{
		#{$property}: rgba($theme-02, $transparency);
	}
	.theme03 & {
		#{$property}: rgba($theme-03, $transparency);
	}
	.theme04 &{
		#{$property}: rgba($theme-04, $transparency);
	}
	.theme05 &{
		#{$property}: rgba($theme-05, $transparency);
	}
	.theme06 &{
		#{$property}: rgba($theme-06, $transparency);
	}
	.theme07 &{
		#{$property}: rgba($theme-07, $transparency);
	}
	.theme08 &{
		#{$property}: rgba($theme-08, $transparency);
	}
	.theme09 &{
		#{$property}: rgba($theme-09, $transparency);
	}
	.theme10 &{
		#{$property}: rgba($theme-10, $transparency);
	}
}

@mixin theme-important($property, $transparency: 1) {
	#{$property}: $brand-one!important;

	.theme01 &{
		#{$property}: rgba($theme-01, $transparency)!important;
	}
	.theme02 &{
		#{$property}: rgba($theme-02, $transparency)!important;
	}
	.theme03 & {
		#{$property}: rgba($theme-03, $transparency)!important;
	}
	.theme04 &{
		#{$property}: rgba($theme-04, $transparency)!important;
	}
	.theme05 &{
		#{$property}: rgba($theme-05, $transparency)!important;
	}
	.theme06 &{
		#{$property}: rgba($theme-06, $transparency)!important;
	}
	.theme07 &{
		#{$property}: rgba($theme-07, $transparency)!important;
	}
	.theme08 &{
		#{$property}: rgba($theme-08, $transparency)!important;
	}
	.theme09 &{
		#{$property}: rgba($theme-09, $transparency)!important;
	}
	.theme10 &{
		#{$property}: rgba($theme-10, $transparency)!important;
	}
}

@mixin theme-textcolor($default: white) {
	color: $default;

	.theme01 &,
	.theme02 &,
	.theme03 &,
	.theme04 &,
	.theme06 &,
	.theme08 &,
	.theme10 & {
		color: white!important;
	}
	.theme05 &,
	.theme07 &,
	.theme09 &{
		color: $text-color!important;
	}
}