.searchContainer {
    display: inline-block;
    width: 100%;
    text-align: right;
    height: $search-height;

    @media (min-width: $screen-lg) {
        max-width: 480px;
        max-height: 44px;
        margin: 20px 0 0;
    }
}

.search {
    width: 100%;
    height: $search-height;
    background-color: $search-bg;
    border-radius: $search-border-radius;
}

/*==================
Cloud Search
====================*/

.gsc-control-searchbox-only {
    height: $search-height;

    * {
        box-sizing: border-box !important;
    }

    td {
        vertical-align: top;
    }
}

.gsc-control-searchbox-only form.gsc-search-box {
    position: relative;
    padding: 0;
    margin: 0!important;
    height: 100%;
}

.gsc-control-searchbox-only form.gsc-search-box table.gsc-search-box {
    position: relative;
    margin: 0!important;
    height: $search-height;

    td.gsc-input {
        padding: 0;
        height: $search-height;

        td {
            padding: 0;
        }
    }
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-input {
    width: 100%;
    height: $search-height;
    color: $search-text-color;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    overflow: hidden;
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-input::placeholder {
    font-size: em(16px);
    color: $search-text-color;
    opacity: 1;
}

.gsc-control-searchbox-only form.gsc-search-box input.gsc-search-button {
    height: $search-height - 2;
    width: 38px;
    border: none;
    margin: 0!important;
    padding: 0;
    text-indent: -9999px;
    background: transparent !important;
    border-radius: 0;
    min-width: 0;
}

.gsc-control-searchbox-only form.gsc-search-box td.gsc-search-button .gsc-search-button-v2 {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    width: $search-height;
    height: $search-height;
    padding: 0;
    margin: 0 !important;
    background: transparent !important;
    border: 0;
    border-radius: 0;

    &:before {
        @include icomoon();
        color: $brand-one;
        content: $icon-search;
        font-size: rem(20px);
    }

    svg {
        display: none;
    }

    &:hover,
    &:focus {
        background-color: $brand-one!important;

        &:before {
            color: white;
        }
    }
}

.gsc-control-searchbox-only form.gsc-search-box td.gsc-search-button {
    position: absolute;
    left: 0;
    margin: 0;
    padding: 0;
}

.gsc-control-searchbox-only form.gsc-search-box td.gsc-clear-button {
    width: auto;
    position: absolute;
    right: 30px;
    top: 5px;
}

div.gsc-clear-button{
    display: none !important;
}


/*GOOGLE SEARCH RESULTS*/
.gsc-control-cse {
    margin-top: 5px;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-control-cse .gsc-table-result {
    font-family: inherit;
}

.gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-title, .gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-title * {
    color: #15C !important;
}

.gsc-control-cse .gsc-control-wrapper-cse .gs-result a.gs-visibleUrl, .gsc-control-cse .gsc-control-wrapper-cse .gs-result .gs-visibleUrl {
    color: #093 !important;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-webResult.gsc-result:hover, .gsc-control-cse .gsc-control-wrapper-cse .gsc-imageResult:hover {
    border-color: #eee;
    background-color: #f8f8f8;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-page {
    background-color: #eee;
    color: #333;
    padding: 7px 12px;
    text-decoration: none;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-page:hover {
    color: #fff;
    background-color: #15C;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
    text-shadow: none;
    color: #fff;
    background-color: #15C;
}

.gsc-control-cse .gsc-control-wrapper-cse .gsc-option-menu-container.gsc-inline-block .gsc-selected-option-container.gsc-inline-block {
    width: 100px!important;
}

// -----------------------------------------------------
// CUSTOM SEARCH
// -----------------------------------------------------

.gsc-input-box {
    display: flex;
    align-items: center;
    position: relative;
    height: $search-height;
    background-color: transparent !important;
    border: 0 !important;
}

.search {
    input.gsc-input {
        padding-left: 50px !important;
        height: $search-height!important;
        background-color: $search-bg!important;
        background-position: right 20px center!important;

        &:focus {
            outline: 5px auto $link-color !important;
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }

    table.gsc-input {
        height: $search-height!important;

        @media (min-width: $screen-lg) {
            max-width: 480px;
        }

        .gsc-input {
            background-color: $search-bg;
        }
    }

    .gsst_a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        width: $search-height;
        height: $search-height;
        color: $link-color;

        .gscb_a {
            color: $link-color;
        }

        &:active,
        &:focus,
        &:hover {
            color: $link-hover-color;

            span {
                color: $link-hover-color;
            }
        }
    }
}