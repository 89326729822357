//  ======================================================
//  CONTACTAUTOCOMPLETE.SCSS
//  styles for Contact module
//  ======================================================

#contactMainContainer {
	background: #DFF8FF;
	float: left;
	width: 100%;
	margin: 0 0 30px;
	padding: 0 30px;
	position: relative;
	overflow: hidden;

	.contactContainer {
		overflow: hidden;
	}

	.contactHeader {
		padding: 0;
		float: left;
		width: 100%;
	}

	.contactHeaderTitle {
		display: block;
		padding: 31px 0 22px;
		border-bottom: 1px solid rgba($brand-one, .2);
		overflow: hidden;

		img {
			width: 22px;
			height: 22px;
			margin-right: 12px;
		}

		p {
			font-size: em(25px);
			letter-spacing: -0.024em;
			line-height: 1.36;
			font-weight: 600;
			margin: 0 !important;
		}
	}

	.contactContainer .contactBody {
		float: left;
		width: 100%;
		font-size: $font-size-small;
		padding: 15px 25px 15px 0;

		@media (min-width: $screen-sm) {
			font-size: 1em;
		}

		p {
			margin: 0 0 10px 0;
			line-height: 26px;
		}

		strong {
			font-size: em(18px);
			font-weight: bold;
		}
	}

	.contactContainer .autoCompleteContainer {
		text-align: left;
		padding: 10px 20px;
		width: 100%;
	}
}

#contactMainContainer_AutoComplete {
	width: 100px;
}
