//  ======================================================
//  MOBILENAV.SCSS
//  styles for main mobile nav
//  ======================================================

.mobileNav {
	display: table-cell;
	width: 140px;
	text-align: right;
	vertical-align: middle;

	@media (min-width: $screen-lg) {
		display: none;
	}

	a,
	button {
		vertical-align: middle;
	}
}

.mobileNav a {
	display: inline-block;
	width: 45px;
	height: 45px;
	margin-left: 4px;
	font-size: 30px;
	text-align: center;
	text-decoration: none;
	color: $text-color;
	background-color: $gray-background;
	border: 1px solid $gray-border;
	border-radius: $border-radius-small;

	&:focus,
	&:hover {
		color: $text-color;
	}
}

.mobileNav a img {
	width: 45px;
	height: 45px;
	margin: 0 auto;
	display: block;
}

.menuTrigger {
	//@include burger(width, height, border, border-radius, background, padding, bar-height, gutter, color, bar-border-radius, transition-duration);
	@include burger(45px, 45px, 1px solid #ccc, 2px, #f3f3f3, 5px, 4px, 5px, #222, 5px);

	&.is-active {
		@include burger-to-cross;
	}
}
