$footerNav-item-spacing:				30px !default; // space between links
$footerNav-item-color:					null !default;
$footerNav-item-font-size:				null !default;
$footerNav-item-font-weight:			null !default;
$footerNav-item-decoration:				null !default;
$footerNav-item-hover-decoration:		null !default;

#footerNav {
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
	padding: 0 0 26px;
	color: $footerNav-item-color;
	border-bottom: 1px solid rgba(#FFFFFF,.2);

	@media (min-width: $screen-md){
		justify-content: flex-start;
	}

    a {
		display: inline-block;
		max-width: 200px;
		color: $footerNav-item-color;
		font-size: $footerNav-item-font-size;
		font-weight: $footerNav-item-font-weight;
		text-decoration: $footerNav-item-decoration;
        padding: 5px 10px;
        max-width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover, &:focus, &:active {
			text-decoration: $footerNav-item-hover-decoration;
		}
	}
}

.footerText a, .footerText a:link, .footerText a:visited {
	color: $footerNav-item-color;

    &:hover, &:focus, &:active {
		text-decoration: $footerNav-item-hover-decoration;
	}
}