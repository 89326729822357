#translateContainer {
	display: flex;
	align-items: center;
	position: relative;
	width: 100%;
	color: $topnav-link-color;
	transition: opacity 0.2s ease-in-out;

	@media (min-width: $screen-lg) {
		display: inline-flex;
		padding-left: 1px;
		min-width: 145px;
		width: 145px;
	}

	&:before {
		position: absolute;
		top: 50%;
		left: 0;
		width: 1px;
		height: 21px;
		background-color: rgba(white, .25);
		transform: translateY(-50%);
		content: '';
	}

	#google_translate_element {
		display: flex;
		align-items: center;
		padding: 0;
		width: 100%;
		height: 100%;
	}

	.goog-te-gadget {
		position: relative;
		display: flex;
		align-items: center;
		width: 100%;
		height: 100%;
		font-size: 0;
		color: $topnav-link-color !important;

		&::before,
		&::after {
			@include icomoon();
			display: inline-block;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			color: black;

			@media (min-width: $screen-lg) {
				color: white;
			}
		}

		&:before {
			content: $icon-translate;
			left: 15px;
			font-size: 16px;
		}

		&:after {
			content: $icon-chevron-thick-down;
			right: 15px;
			font-size: 10px;
		}

		> div {
			width: 100%;
			height: 100%;
		}

		&:hover,
		&:focus {
			color: white !important;
			background-color: $brand-two;

			&:before,
			&:after,
			* {
				color: white !important;
			}
		}

		.goog-te-combo {
			width: 100%;
			height: 100%;
			margin: 0;
			padding: 11px 32px 11px 40px;
			border: 0;
			font-size: 1rem;
			line-height: $topnav-line-height;
			font-weight: $topnav-font-weight;
			font-family: $font-family-base;
			text-decoration: $topnav-link-decoration;
			color: black;
			vertical-align: baseline;
			background-color: transparent;
			background-image: none !important;
			opacity: 0;
			-webkit-appearance: none;
			transition: opacity $transition-base;

			@media (min-width: $screen-lg) {
				width: 100%;
				font-size: $topnav-font-size;
				color: $topnav-link-color;
			}

			> :first-child {
				display: none;
			}

			.loaded & {
				opacity: 1;
			}
		}

		select {
			option {
				color: color-contrast($brand-one) !important;
				background-color: $brand-one !important;
			}
		}


		select::-ms-expand {
			display: none !important;
		}

		.goog-logo-link {
			display: none;
		}
	}

	.goog-te-gadget-simple {
		padding: 0;
		border: none;
		background-color: transparent;

		@media (max-width: $screen-lg - 1) {
			width: 100%;
			text-align: left;
		}
	}

	.goog-te-menu-value {
		margin: 0;
		padding: 0;

		span {
			font-family: $font-family-base;
			color: $topnav-link-color;
			font-size: $topnav-font-size;
			line-height: $topnav-line-height;
			font-weight: $topnav-font-weight;
			text-decoration: $topnav-link-decoration;

			@media (max-width: $screen-lg - 1) {
				width: 100%;
				padding: 12px 16px;
				text-align: left;
				font-weight: normal;
				font-size: 1rem;
			}

			&:after {
				@include icomoon();
				display: inline-block;
				width: 12px;
				height: 12px;
				color: #000;
				content: $icon-plus;
			}
		}

		&:hover span,
		&:active span,
		&:focus span {
			text-decoration: $topnav-link-hover-decoration;
			background-color: #C3E2E5;
		}
	}

	.goog-te-gadget-simple>img {
		display: none;
	}

	.goog-te-menu-value img,
	.goog-te-menu-value span {
		display: none;
	}

	.goog-te-menu-value span:first-child {
		display: inline-block;
	}
}