.emergencyBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    width: 100%;

    @media (min-width: $screen-xl) {
        width: 100%;
        max-width: none;
    }

    &::before,
    &::after {
        display: none;
    }
}

.emergencyBarButtons {
    // hide close button (client request)
    display: none;
}

.emergencyBarSlider {
    position: relative;
    width: 100%;
    min-height: 100px; // prevent main content jump on load
    color: inherit;
    overflow: hidden;

    &.hide,
    &:empty {
        display: none;
        min-height: 0;
    }

    .flickity-button {
        top: 50%;
        width: 49px;
        height: 49px;
        line-height: 1;
        color: currentColor;
        background: transparent;
        border-radius: 50%;
        border: em(1.5px) solid currentColor;
        transform: translateY(-50%);
        transition: none;

        @media (max-width: $screen-lg - 1) {
            display: none;
        }

        &::after {
            @include icomoon();
            content: $icon-arrow-right;
        }

        &:active,
        &:focus,
        &:hover {
            background: rgba(black, .1);
        }

        &.next {
            right: $container-padding;
            left: auto;

            @media (min-width: $container-wide-mq) {
                right: $container-wide-offset;
            }
        }

        &.pause {
            right: calc(57px + #{$container-padding});

            @media (min-width: $container-wide-mq) {
                right: calc(57px + #{$container-wide-offset});
            }

            &::after {
                content: $icon-pause-thin;
            }

            &.paused {
                &::after {
                    content: $icon-play;
                }
            }
        }

        &.previous {
            top: 50%;
            right: calc(114px + #{$container-padding});
            left: auto;
            transform: translateY(-50%);

            @media (min-width: $container-wide-mq) {
                right: calc(114px + #{$container-wide-offset});
            }

            &::after {
                content: $icon-arrow-left;
            }
        }
    }

    .flickity-page-dots {
        bottom: 10px;

        @media (min-width: $screen-lg) {
            display: none;
        }

        .dot {
            background: currentColor;
        }
    }

    .flickity-slider {
        display: flex;
    }

    &.flickity-enabled {
        min-height: unset; // sticky header slide fix
    }
}

.emergencyBarText {
    flex-grow: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow: hidden;

    @media (max-width: $screen-xs - 1) {
        flex-direction: column;
    }

    @media (min-width: $screen-lg) {
        max-width: calc(100% - 178px);
    }

    * {
        color: inherit;
    }

    .icon {
        flex-shrink: 0;
        margin-bottom: 10px;
        min-width: 28px;

        @media (min-width: $screen-xs) {
            margin-right: 15px;
            margin-bottom: 0;
        }

        @media (min-width: $screen-md) {
            margin-right: 24px;
            min-width: 55px;
        }

        &:before {
            @include icomoon();
            content: $icon-alert;
            font-size: 24px;

            @media (min-width: $screen-md) {
                font-size: 48px;
            }
        }
    }

    .text {
        * {
            margin-top: 0;
            margin-bottom: 0;
        }

        a {
            text-decoration: underline;
            color: inherit;
        }

        p {
            font-size: 1em;
            letter-spacing: -0.024em;
            line-height: 1.375;

            &:first-child {
                font-weight: bold;

                @media (min-width: $screen-md) {
                    font-size: em(21px);
                }
            }
        }
    }
}

.emergencyBarWrapper {
    position: absolute;
    padding: 10px $container-padding 30px;
    width: 100%;
    min-height: 100%;
    background-color: $body-bg;
    transition: color $transition-base, background-color $transition-base;

    @media (min-width: $screen-lg) {
        padding-top: 25px;
        padding-bottom: 25px;
    }

    @media (min-width: $container-wide-mq) {
        padding-right: $container-wide-offset;
        padding-left: $container-wide-offset;
    }

    &.blue {
        color: color-contrast($alert-blue);
        background-color: $alert-blue;
    }

    &.green {
        color: color-contrast($alert-green);
        background-color: $alert-green;
    }

    &.red {
        color: color-contrast($alert-red);
        background-color: $alert-red;
    }

    &.yellow {
        color: color-contrast($alert-yellow);
        background-color: $alert-yellow;
    }
}

// Prevent flash of other alert banners while loading
body:not(.loaded) {
    .emergencyBarWrapper {
        // background-color: $body-bg !important;

        &:first-child {
            z-index: 1;
        }
    }
}