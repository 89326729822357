.topNavQuicklinksContainer {
	@media (max-width: $screen-lg - 1) {
		width: 100%;
	}
}

.topNavQuicklinksBtn {
	display: flex;
	align-items: center;
	height: 100%;
	padding: 11px 22px;
	font-weight: bold;
	color: #fff;
	background-color: $brand-two;
	border: 0;
	box-shadow: none;
	vertical-align: middle;

	@media (max-width: $screen-lg - 1) {
		padding-right: 1rem;
		padding-left: 1rem;
		width: 100%;
		text-align: left;
	}

	&:before {
		@include icomoon();
		display: inline-block;
		width: 14px;
		margin-right: 12px;
		content: $icon-quicklinks;
	}
}

.topNavQuicklinksNav {
	display: none;
}