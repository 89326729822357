$footer-bg: $brand-one !default;
$footer-font-size: 1em !default;
$footer-line-height: $line-height-base !default;
$footer-text-color: $gray-lighter !default;
$footer-link-color: white !default;
$footer-link-decoration: none !default;
$footer-link-hover-decoration: underline !default;
$footer-left-max-width: 135px !default;

@import 'footerNav';
@import 'footerNavMega';

#footer {
	width: 100%;
	background: $footer-bg;
	color: $footer-text-color;
	font-size: $footer-font-size;
	line-height: $footer-line-height;
	padding: 0 0 1em;
	z-index: 1;
	position: relative;

	@media (min-width: $screen-md) {
		padding: 0 0 40px;
	}

	a {
		color: $footer-link-color;
		text-decoration: $footer-link-decoration;

		&:hover {
			text-decoration: $footer-link-hover-decoration;
		}

		&[href^=tel] {
			color: $footer-link-color;
		}
	}
}

.footerText {
	h2 {
		margin: 1em 0 0;
		font-size: em(21px);
		font-weight: 600;

		@media (min-width: $screen-md) {
			margin: 0;
		}
	}
	> * {
		text-overflow: ellipsis;
		overflow: hidden;
	}
	p {
		display: block;

		@media (min-width: $screen-md) {
			float: left;
			width: 33.3333%;
			max-width: 196px;
		}

		a,
		a:visited,
		a:active {
			color: $brand-three!important;
		}
	}
}

.footerCopyright {
	display: block;
	flex-grow: 1;
	padding-top: 18px;

	@media (min-width: $screen-md) {
		display: inline-block;
	}

	p{
		margin: 0;
	}
}

#esol {
	display: inline-block;
	padding-top: 18px;

	a {
		color: white;
		font-weight: bold;
	}
}

/*==================
Footer Top
====================*/

.footerTop {
	padding: 30px 0;
	width: 100%;
	text-align: center;
	text-overflow: ellipsis;
	overflow: hidden;

	@media (min-width: $screen-md) {
		display: flex;
		padding-top: 45px;
		text-align: left;
	}

	> * {
		min-width: 1px;
	}
}

.footerTopLeft {
	width: 100%;
	text-align: center;

	@media (min-width: $screen-md) {
		flex-shrink: 0;
		width: $footer-left-max-width;
		text-align: left;
	}
}

.footerTopRight {
	.socialLinks {
		h2 {
			max-width: 250px;
			margin: 0 0 15px;
			font-size: em(21px);
			font-weight: 600;
			text-overflow: ellipsis;
			overflow: auto;
		}

		ul {

			padding: 0;
			list-style: none;

			li {
				display: inline-block;
				margin: 0 10px;

				&:first-of-type {
					margin-left: 0;
				}
			}
		}

		a {
			position: relative;
			display: block;
			width: 40px;
			height: 40px;
			margin: 0;
			@include socialIcons('circle', white, $brand-one, 20px);

			&:first-of-type {
				margin-left: 0;
			}
			&:last-of-type {
				margin-right: 0;
			}

			&:hover,
			&:focus {
				@include socialIcons('cicle', $brand-three, black, 20px);
			}
		}
	}
}

/*==================
Footer Logo
====================*/

.footerLogo {
	margin-right: auto;
	margin-left: auto;
	width: $footer-left-max-width;
	max-width: 100%;

	img {
		max-width: 110px;
		height: auto;
	}

	p {
		margin-top: 0;
		margin-bottom: 0;
	}
}

/*==================
Footer Bottom
====================*/

.footerBottom {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	width: 100%;
	padding: 20px 0;
	min-height: 80px;
	clear: both;
}