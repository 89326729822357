//  ======================================================
//  IDLIKETO.SCSS
//  I'd Like to styles
//  ======================================================

// SEARCH MODAL
.modal-cover {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(#182632, .72);
    z-index: 9990;
}

.modal-box {
    display: none;
    width: 90vw;
    position: absolute;
    top: 30vh;
    right: 0;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    max-width: $container-max-width;
    border-radius: $search-border-radius;
    z-index: 9999;

    // Only fixed if screen is big enough, we want avoid screen lock w/ hidden elements living off screen
    @media (min-width: $screen-md) and (min-height: 1000px) {
        position: fixed;
    }

    .modal-body {
        max-width: 100%;
        padding: 0;
        overflow: auto;
    }

    .searchContainer {
        width: 100%;
        height: 65px;
        margin: 0;
        padding: 0;
    }

    .search {
        width: 100%;
        max-width: none;
        height: 45px;
        border: none !important;

        input {
            padding-left: 15px;
        }

        .searchInput {
            border-radius: $search-border-radius 0 0 $search-border-radius;
        }

        .searchButton {
            width: 40px;
            background-color: transparent !important;
            background-image: url(../../Common/images/structure/search-icon.svg) !important;
            background-size: 19px 19px !important;
            border-radius: 0 $search-border-radius $search-border-radius 0;
            transition: ease background-color 0.3s !important;

            &:hover,
            &:focus {
                background-color: $brand-one !important;
            }

            @media (min-width: $screen-md) {
                width: 60px;
                background-size: 24px 24px !important;

                &:hover,
                &:focus {
                    background-size: 24px 24px !important;
                }
            }
        }
    }
}

#searchPopup a.close {
    width: 30px;
    height: 30px;
    font-size: 30px;
    display: inline-block;
    position: absolute;
    top: -42px;
    right: -7px;
    color: #fff !important;
    line-height: 1.5rem;
    letter-spacing: .03125em;
    text-align: center;
    text-decoration: none;
    border-radius: $search-border-radius;
    text-shadow: none;
    opacity: 1;

    &:hover,
    &:focus {
        background-color: white;
    }
}


// CONNECT + ID LIKE TO MODAL

#idLikeToModal,
#connectModal {
    display: none;
    margin-right: auto;
    margin-left: auto;
    top: 4vh;
    right: 0;
    left: 0;
    width: 90vw;
    max-width: 1076px;
    color: $text-color;
    background-color: white;
    box-shadow: $box-shadow-large;
    border-radius: 3px;
    z-index: 9999;

    @media (min-width: $screen-md) {
        top: 16vh;
    }

    .close {
        position: absolute;
        top: 15px;
        right: 0;
        padding: 18px;
        font-size: 0;
        opacity: 1;
        transform: translateX(-50%);
        background-color:white;
        border-radius: 50%;
        box-shadow: 0 3px 6px rgba(black, .2);
        transition: all .3s ease-in-out;

        @media (min-width: $screen-sm){
            top: 63px;
            right: auto;
            left: 0;
        }

       &:before {
           @include icomoon();
           content: $icon-close;
           font-size: 13px;
       }

        &:hover,
        &:focus {
            background-color: $gray-background;
        }
    }

    .modal-header {
        position: relative;
        width: 100%;
        padding: 0 15px;
        text-align: left;
        border: 0;

        @media (min-width: $screen-md) {
            padding: 0 51px;
        }
    }

    .modal-title {
        display: block;
        max-width: 100%;
        margin: 0 auto;
        padding: 56px 0 32px;
        font-size: em(36px);
        font-weight: 600;
        color: $text-color;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;

        img,
        svg {
            width: 20px;
            height: 20px;
            margin-right: 11px;
        }

        svg * {
            fill: #000;
        }
    }

    .modal-body {
        display: flex;
        padding: 15px;
        width: 100%;
        padding: 0 15px;
        flex-wrap: wrap;
        text-align: center;
        text-overflow: ellipsis;
        overflow: auto;

        @media (min-width: $screen-md) {
            flex-wrap: nowrap;
            padding: 0 51px;
        }
    }

    .modalCol {
        text-align: left;
        display: block;
        width: 100%;
        padding: 10px;

        &:first-child {
            border-left: none;
        }

        @media (min-width: $screen-xs) {
            width: 50%;
            ;
        }

        @media (min-width: $screen-md) {
            max-width: calc(100% / 3);
            border-left: 1px solid #E1E1E1;
        }

        @media (min-width: $screen-lg) {
            padding: 0 10px 0 30px;
        }

        @media (max-width: $screen-md) {
            text-align: left;
        }

        .image-wrap {
            display: inline-flex;
            height: 50px;
            width: 50px;
            margin: 0 auto 20px auto;
            align-items: center;
            justify-content: center;

            @media (max-width: $screen-md) {
                display: none;
            }

            >img {
                height: 50px;
                width: 50px;
            }
        }

        >img {
            width: 58px;
            height: 58px;
            margin: 10px auto;
            background-color: $brand-one;
            border-radius: 33px;
        }

        &:first-child {
            border: none;
        }

        &:nth-child(3) {
            clear: both;
        }

        * {
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        h3 {
            font-size: rem(17px);
            font-weight: 600;
            line-height: 1.3;
            padding: 0;
            margin: 0 0;
            width: 100%;
            padding-top: 24px;
            overflow: hidden;

            &:nth-of-type(1) {
                padding-top: 0;
            }

            &+p {
                margin-top: 6px;
            }
        }

        br:first-child {
            content: "";
            display: block;
            margin: 13px 0 0 0;
        }

        li {
            display: flex;
            margin: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            width: 100%;
            margin: 16px 0 0;
            padding-left: 0;
            list-style: none;
            overflow: hidden;
        }

        a {
            display: inline-block;
            max-width: 100%;
            font-size: rem(16px);
            font-weight: normal;
            line-height: 2;
            letter-spacing: -0.01em;
            color: $text-color;
            text-overflow: ellipsis;
            overflow: hidden;

            @media (min-width: $screen-md) {
                font-size: rem(16px);
            }
        }

        p {
            font-size: rem(15px);

            @media (min-width: $screen-md) {
                font-size: rem(16px);
            }
        }

        a.viewMore {
            font-weight: bold;
        }
    }

    .modal-search {
        padding: 0;
        clear: both;

        .searchContainer .search {
            width: calc(100% - 50px) !important;
            height: 65px;
            border-radius: 0;
            font-size: $font-size-base;
            margin: 0 auto 45px auto;
            border: 2px solid rgba(#DDDDDD, .46);
            background-color: white;

            @media (min-width: $screen-md) {
                width: calc(100% - 80px) !important;
            }

            input {
                font-size: $font-size-base;
            }

            .searchButton {
                border-radius: 0;
                background-color: transparent !important;

                &:hover,
                &:focus {
                    background-color: $brand-one !important;
                }
            }
        }
    }

    .socialLinks {
        width: 100%;
        font-size: 0px;
        line-height: 0;
        margin: 14px 0 8px 0;

        a {
            display: inline-block;
            margin: 0 3px;
            transform: scale(1);
            transition: transform 0.2s ease-in-out;

            @media (min-width: $screen-md) {
                margin: 0 4px;
            }

            &:hover,
            &:focus {
                transform: scale(1.1);
            }

            &:first-child {
                margin-left: 0;
            }

            &:last-child {
                margin-right: 0;
            }

            img {
                display: block;
                width: 30px;
                height: 30px;
                border-radius: 3px;

                @media (min-width: $screen-md) {
                    width: 34px;
                    height: 34px;
                }
            }

            &:focus img {
                outline: 1px dotted black;
            }
        }
    }

    .connect-left-container {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
        flex-direction: row;
        -webkit-align-items: center;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: $screen-md) {
            margin-top: -8px;
        }

        .connect-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            background-color: #fff;
            padding: 11px;
            text-decoration: none;
            color: $text-color;
            box-shadow: 0 3px 6px rgba(#000, 0.16);
            transform: scale(1);
            transition: all 0.2s ease-in-out;
            position: relative;

            @media (min-width: $screen-xs) {
                align-items: flex-start;
                flex-direction: row;
            }

            &:hover,
            &:focus {
                transform: scale(1.015);
                box-shadow: 0 3px 6px rgba(#000, 0.35);
            }

            &:first-of-type {
                margin-bottom: 20px;
            }

            .connect-content {
                position: relative;
                padding: 10px 15px 0 15px;

                @media (min-width: $screen-xs) {
                    padding: 10px 0 0 16px;
                }
            }

            img {
                width: 100%;
                max-width: 180px;
                height: auto;
                flex-grow: 0;
                flex-shrink: 0;

                @media (min-width: $screen-xs) {
                    display: block;
                    height: 118px;
                    width: 118px;
                }
            }

            h3 {
                font-size: rem(18px);
                font-weight: 700;
                color: $link-color;
                line-height: rem(22px);
                padding: 0;

                @media (min-width: $screen-md) {
                    font-size: rem(19px);
                }
            }

            p {
                font-weight: normal;
                margin-top: 8px;
                margin-bottom: 0;
                line-height: 1.55;
            }
        }
    }
}

#connectModal {
    max-width: 1182px;

    .modalCol {
        padding: 0 25px;
        border-left: none;
        width: 100%;

        &:nth-of-type(2) {
            margin-top: 10px;
            padding-top: 20px;
            border-top: 1px solid rgba(0, 0, 0, .2);
        }

        &:nth-of-type(3) {
            width: 100%;
            margin-top: 10px;
            padding-top: 25px;
            border-top: 1px solid rgba(0, 0, 0, .2);
        }

        @media (min-width: $screen-xs) {
            width: 50%;

            &:nth-of-type(2) {
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }

        @media (min-width: $screen-md) {
            width: 32.25%;

            &:nth-of-type(3) {
                width: 35.5%;
                margin-top: 0;
                padding-top: 0;
                border-top: none;
            }
        }
    }
}


// ID LIKE TO MODAL - INDIVIDUAL STYLES

#idLikeToModal {

    .modalCol {
        width: 100%;
        padding: 10px 10px 20px 10px;

        @media (min-width: $screen-sm) {
            width: 50%;

            &:last-child {
                width: 100%;
            }
        }

        @media (min-width: $screen-md) {
            width: 100%;
            text-align: center;

            &:last-child {
                width: 100%;
            }
        }

        @media (min-width: $screen-lg) {
            padding: 0 25px;
        }

        a[class^="defaultButton-"] {
            color: #fff;
            padding: 10px 42px;
        }

        .image-wrap {
            margin: 0 auto 8px auto;

            img {
                width: 37px;
                height: auto;
            }
        }
    }

    .searchContainer {
        margin-top: 12px;
        margin-bottom: 32px;

        .search {
            border: 1px solid #D1D1D1 !important;
            border-radius: 22px;
        }

        .searchInput {
            background-color: transparent;

            &:focus {
                outline: none;
            }
        }
    }
}