//  ======================================================
//  BASE.SCSS
//  Base styles for all sites. Includes reset and Bootstrap styles
//  ======================================================

html, body, header, footer {
  min-width: 300px;
}

body, html {
	width: 100%;
}

#mainContent {
  outline: none;
  &:focus {
    outline: none;
  }
}

// Apply Box Sizing to all elements
* {
  box-sizing: border-box;
  &:after, &:before {
    box-sizing: border-box;
  }
}

// SET MIN-WIDTH ON UBER FOR BROWSERS THAT DON'T SUPPORT MEDIA QUERIES
.no-mediaqueries {
  #uber, header, footer {
    min-width: 992px;
  }
}

// REMOVE OUTLINE ON PRINTAREA
#printAreaContentDotNet:focus, #printAreaContent:focus {
  outline: none;
}

// Reset box sizing for ui time picker
#ui-timepicker-div * {
  box-sizing: content-box;
}

// TABLES

// Remove most spacing between table cells.
table {
  border-collapse: collapse;
  border-spacing: 0;
}

// Div Clearing and Clearfix
.clear {
  clear: both;
}

.cf {
  &:before {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
  }
  &:after {
    content: " ";
    /* 1 */
    display: table;
    /* 2 */
    clear: both;
  }
  *zoom: 1;
}

// BOOTSTRAP STYLES AND RESET

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden], template {
  display: none;
}

a {
  background: transparent;
  &:active, &:hover {
    outline: 0;
  }
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

mark {
  background: #ff0;
  color: #000;
}

small {
  font-size: 80%;
}

sub {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
  text-transform: none;
}

select {
  text-transform: none;
}

button, html input[type="button"] {
  -webkit-appearance: button;
  cursor: pointer;
}

input {
  &[type="reset"], &[type="submit"] {
    -webkit-appearance: button;
    cursor: pointer;
  }
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  &::-moz-focus-inner {
    border: 0;
    padding: 0;
  }
  line-height: normal;
  &[type="checkbox"], &[type="radio"] {
    box-sizing: border-box;
    padding: 0;
  }
  &[type="number"] {
    &::-webkit-inner-spin-button, &::-webkit-outer-spin-button {
      height: auto;
    }
  }
  &[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
    &::-webkit-search-cancel-button, &::-webkit-search-decoration {
      -webkit-appearance: none;
    }
  }
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

html {
  font-size: 10px;
}

body {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333333;
  background-color: #ffffff;
}

input, button, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: #428bca;
  text-decoration: none;
  &:hover {
    color: #2a6496;
    text-decoration: underline;
  }
  &:focus {
    color: #2a6496;
    text-decoration: underline;
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

figure {
  margin: 0;
}

img,
svg {
  vertical-align: middle;
}

.ic-img-responsive {
  display: block;
  width: 100%;
  max-width: 100%;
  height: auto;
}

.ic-thumbnail {
  > img, a > img {
    display: block;
    width: 100%;
    max-width: 100%;
    height: auto;
  }
}

.ic-img-rounded {
  border-radius: 6px;
}

.ic-img-thumbnail {
  padding: 4px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  display: inline-block;
  width: 100% \9;
  max-width: 100%;
  height: auto;
}

.ic-img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eeeeee;
}

.ic-sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.ic-sr-only-focusable {
  &:active, &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.ic-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .ic-container {
    width: 750px;
  }
  .ic-row {
    margin-left: -15px;
    margin-right: -15px;
  }
}

@media (min-width: 992px) {
  .ic-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .ic-container {
    width: 1170px;
  }
}

.ic-container-fluid {
  margin-right: auto;
  margin-left: auto;
}

.ic-col-xs-1, .ic-col-sm-1, .ic-col-md-1, .ic-col-lg-1, .ic-col-xs-2, .ic-col-sm-2, .ic-col-md-2, .ic-col-lg-2, .ic-col-xs-3, .ic-col-sm-3, .ic-col-md-3, .ic-col-lg-3, .ic-col-xs-4, .ic-col-sm-4, .ic-col-md-4, .ic-col-lg-4, .ic-col-xs-5, .ic-col-sm-5, .ic-col-md-5, .ic-col-lg-5, .ic-col-xs-6, .ic-col-sm-6, .ic-col-md-6, .ic-col-lg-6, .ic-col-xs-7, .ic-col-sm-7, .ic-col-md-7, .ic-col-lg-7, .ic-col-xs-8, .ic-col-sm-8, .ic-col-md-8, .ic-col-lg-8, .ic-col-xs-9, .ic-col-sm-9, .ic-col-md-9, .ic-col-lg-9, .ic-col-xs-10, .ic-col-sm-10, .ic-col-md-10, .ic-col-lg-10, .ic-col-xs-11, .ic-col-sm-11, .ic-col-md-11, .ic-col-lg-11, .ic-col-xs-12, .ic-col-sm-12, .ic-col-md-12, .ic-col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.ic-col-xs-1, .ic-col-xs-2, .ic-col-xs-3, .ic-col-xs-4, .ic-col-xs-5, .ic-col-xs-6, .ic-col-xs-7, .ic-col-xs-8, .ic-col-xs-9, .ic-col-xs-10, .ic-col-xs-11 {
  float: left;
}

.ic-col-xs-12 {
  float: left;
  width: 100%;
}

.ic-col-xs-11 {
  width: 91.66666667%;
}

.ic-col-xs-10 {
  width: 83.33333333%;
}

.ic-col-xs-9 {
  width: 75%;
}

.ic-col-xs-8 {
  width: 66.66666667%;
}

.ic-col-xs-7 {
  width: 58.33333333%;
}

.ic-col-xs-6 {
  width: 50%;
}

.ic-col-xs-5 {
  width: 41.66666667%;
}

.ic-col-xs-4 {
  width: 33.33333333%;
}

.ic-col-xs-3 {
  width: 25%;
}

.ic-col-xs-2 {
  width: 16.66666667%;
}

.ic-col-xs-1 {
  width: 8.33333333%;
}

.ic-col-xs-pull-12 {
  right: 100%;
}

.ic-col-xs-pull-11 {
  right: 91.66666667%;
}

.ic-col-xs-pull-10 {
  right: 83.33333333%;
}

.ic-col-xs-pull-9 {
  right: 75%;
}

.ic-col-xs-pull-8 {
  right: 66.66666667%;
}

.ic-col-xs-pull-7 {
  right: 58.33333333%;
}

.ic-col-xs-pull-6 {
  right: 50%;
}

.ic-col-xs-pull-5 {
  right: 41.66666667%;
}

.ic-col-xs-pull-4 {
  right: 33.33333333%;
}

.ic-col-xs-pull-3 {
  right: 25%;
}

.ic-col-xs-pull-2 {
  right: 16.66666667%;
}

.ic-col-xs-pull-1 {
  right: 8.33333333%;
}

.ic-col-xs-pull-0 {
  right: auto;
}

.ic-col-xs-push-12 {
  left: 100%;
}

.ic-col-xs-push-11 {
  left: 91.66666667%;
}

.ic-col-xs-push-10 {
  left: 83.33333333%;
}

.ic-col-xs-push-9 {
  left: 75%;
}

.ic-col-xs-push-8 {
  left: 66.66666667%;
}

.ic-col-xs-push-7 {
  left: 58.33333333%;
}

.ic-col-xs-push-6 {
  left: 50%;
}

.ic-col-xs-push-5 {
  left: 41.66666667%;
}

.ic-col-xs-push-4 {
  left: 33.33333333%;
}

.ic-col-xs-push-3 {
  left: 25%;
}

.ic-col-xs-push-2 {
  left: 16.66666667%;
}

.ic-col-xs-push-1 {
  left: 8.33333333%;
}

.ic-col-xs-push-0 {
  left: auto;
}

.ic-col-xs-offset-12 {
  margin-left: 100%;
}

.ic-col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.ic-col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.ic-col-xs-offset-9 {
  margin-left: 75%;
}

.ic-col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.ic-col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.ic-col-xs-offset-6 {
  margin-left: 50%;
}

.ic-col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.ic-col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.ic-col-xs-offset-3 {
  margin-left: 25%;
}

.ic-col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.ic-col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.ic-col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .ic-col-sm-1, .ic-col-sm-2, .ic-col-sm-3, .ic-col-sm-4, .ic-col-sm-5, .ic-col-sm-6, .ic-col-sm-7, .ic-col-sm-8, .ic-col-sm-9, .ic-col-sm-10, .ic-col-sm-11 {
    float: left;
  }
  .ic-col-sm-12 {
    float: left;
    width: 100%;
  }
  .ic-col-sm-11 {
    width: 91.66666667%;
  }
  .ic-col-sm-10 {
    width: 83.33333333%;
  }
  .ic-col-sm-9 {
    width: 75%;
  }
  .ic-col-sm-8 {
    width: 66.66666667%;
  }
  .ic-col-sm-7 {
    width: 58.33333333%;
  }
  .ic-col-sm-6 {
    width: 50%;
  }
  .ic-col-sm-5 {
    width: 41.66666667%;
  }
  .ic-col-sm-4 {
    width: 33.33333333%;
  }
  .ic-col-sm-3 {
    width: 25%;
  }
  .ic-col-sm-2 {
    width: 16.66666667%;
  }
  .ic-col-sm-1 {
    width: 8.33333333%;
  }
  .ic-col-sm-pull-12 {
    right: 100%;
  }
  .ic-col-sm-pull-11 {
    right: 91.66666667%;
  }
  .ic-col-sm-pull-10 {
    right: 83.33333333%;
  }
  .ic-col-sm-pull-9 {
    right: 75%;
  }
  .ic-col-sm-pull-8 {
    right: 66.66666667%;
  }
  .ic-col-sm-pull-7 {
    right: 58.33333333%;
  }
  .ic-col-sm-pull-6 {
    right: 50%;
  }
  .ic-col-sm-pull-5 {
    right: 41.66666667%;
  }
  .ic-col-sm-pull-4 {
    right: 33.33333333%;
  }
  .ic-col-sm-pull-3 {
    right: 25%;
  }
  .ic-col-sm-pull-2 {
    right: 16.66666667%;
  }
  .ic-col-sm-pull-1 {
    right: 8.33333333%;
  }
  .ic-col-sm-pull-0 {
    right: auto;
  }
  .ic-col-sm-push-12 {
    left: 100%;
  }
  .ic-col-sm-push-11 {
    left: 91.66666667%;
  }
  .ic-col-sm-push-10 {
    left: 83.33333333%;
  }
  .ic-col-sm-push-9 {
    left: 75%;
  }
  .ic-col-sm-push-8 {
    left: 66.66666667%;
  }
  .ic-col-sm-push-7 {
    left: 58.33333333%;
  }
  .ic-col-sm-push-6 {
    left: 50%;
  }
  .ic-col-sm-push-5 {
    left: 41.66666667%;
  }
  .ic-col-sm-push-4 {
    left: 33.33333333%;
  }
  .ic-col-sm-push-3 {
    left: 25%;
  }
  .ic-col-sm-push-2 {
    left: 16.66666667%;
  }
  .ic-col-sm-push-1 {
    left: 8.33333333%;
  }
  .ic-col-sm-push-0 {
    left: auto;
  }
  .ic-col-sm-offset-12 {
    margin-left: 100%;
  }
  .ic-col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .ic-col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .ic-col-sm-offset-9 {
    margin-left: 75%;
  }
  .ic-col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .ic-col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .ic-col-sm-offset-6 {
    margin-left: 50%;
  }
  .ic-col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .ic-col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .ic-col-sm-offset-3 {
    margin-left: 25%;
  }
  .ic-col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .ic-col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .ic-col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .ic-col-md-1, .ic-col-md-2, .ic-col-md-3, .ic-col-md-4, .ic-col-md-5, .ic-col-md-6, .ic-col-md-7, .ic-col-md-8, .ic-col-md-9, .ic-col-md-10, .ic-col-md-11 {
    float: left;
  }
  .ic-col-md-12 {
    float: left;
    width: 100%;
  }
  .ic-col-md-11 {
    width: 91.66666667%;
  }
  .ic-col-md-10 {
    width: 83.33333333%;
  }
  .ic-col-md-9 {
    width: 75%;
  }
  .ic-col-md-8 {
    width: 66.66666667%;
  }
  .ic-col-md-7 {
    width: 58.33333333%;
  }
  .ic-col-md-6 {
    width: 50%;
  }
  .ic-col-md-5 {
    width: 41.66666667%;
  }
  .ic-col-md-4 {
    width: 33.33333333%;
  }
  .ic-col-md-3 {
    width: 25%;
  }
  .ic-col-md-2 {
    width: 16.66666667%;
  }
  .ic-col-md-1 {
    width: 8.33333333%;
  }
  .ic-col-md-pull-12 {
    right: 100%;
  }
  .ic-col-md-pull-11 {
    right: 91.66666667%;
  }
  .ic-col-md-pull-10 {
    right: 83.33333333%;
  }
  .ic-col-md-pull-9 {
    right: 75%;
  }
  .ic-col-md-pull-8 {
    right: 66.66666667%;
  }
  .ic-col-md-pull-7 {
    right: 58.33333333%;
  }
  .ic-col-md-pull-6 {
    right: 50%;
  }
  .ic-col-md-pull-5 {
    right: 41.66666667%;
  }
  .ic-col-md-pull-4 {
    right: 33.33333333%;
  }
  .ic-col-md-pull-3 {
    right: 25%;
  }
  .ic-col-md-pull-2 {
    right: 16.66666667%;
  }
  .ic-col-md-pull-1 {
    right: 8.33333333%;
  }
  .ic-col-md-pull-0 {
    right: auto;
  }
  .ic-col-md-push-12 {
    left: 100%;
  }
  .ic-col-md-push-11 {
    left: 91.66666667%;
  }
  .ic-col-md-push-10 {
    left: 83.33333333%;
  }
  .ic-col-md-push-9 {
    left: 75%;
  }
  .ic-col-md-push-8 {
    left: 66.66666667%;
  }
  .ic-col-md-push-7 {
    left: 58.33333333%;
  }
  .ic-col-md-push-6 {
    left: 50%;
  }
  .ic-col-md-push-5 {
    left: 41.66666667%;
  }
  .ic-col-md-push-4 {
    left: 33.33333333%;
  }
  .ic-col-md-push-3 {
    left: 25%;
  }
  .ic-col-md-push-2 {
    left: 16.66666667%;
  }
  .ic-col-md-push-1 {
    left: 8.33333333%;
  }
  .ic-col-md-push-0 {
    left: auto;
  }
  .ic-col-md-offset-12 {
    margin-left: 100%;
  }
  .ic-col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .ic-col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .ic-col-md-offset-9 {
    margin-left: 75%;
  }
  .ic-col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .ic-col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .ic-col-md-offset-6 {
    margin-left: 50%;
  }
  .ic-col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .ic-col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .ic-col-md-offset-3 {
    margin-left: 25%;
  }
  .ic-col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .ic-col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .ic-col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .ic-col-lg-1, .ic-col-lg-2, .ic-col-lg-3, .ic-col-lg-4, .ic-col-lg-5, .ic-col-lg-6, .ic-col-lg-7, .ic-col-lg-8, .ic-col-lg-9, .ic-col-lg-10, .ic-col-lg-11 {
    float: left;
  }
  .ic-col-lg-12 {
    float: left;
    width: 100%;
  }
  .ic-col-lg-11 {
    width: 91.66666667%;
  }
  .ic-col-lg-10 {
    width: 83.33333333%;
  }
  .ic-col-lg-9 {
    width: 75%;
  }
  .ic-col-lg-8 {
    width: 66.66666667%;
  }
  .ic-col-lg-7 {
    width: 58.33333333%;
  }
  .ic-col-lg-6 {
    width: 50%;
  }
  .ic-col-lg-5 {
    width: 41.66666667%;
  }
  .ic-col-lg-4 {
    width: 33.33333333%;
  }
  .ic-col-lg-3 {
    width: 25%;
  }
  .ic-col-lg-2 {
    width: 16.66666667%;
  }
  .ic-col-lg-1 {
    width: 8.33333333%;
  }
  .ic-col-lg-pull-12 {
    right: 100%;
  }
  .ic-col-lg-pull-11 {
    right: 91.66666667%;
  }
  .ic-col-lg-pull-10 {
    right: 83.33333333%;
  }
  .ic-col-lg-pull-9 {
    right: 75%;
  }
  .ic-col-lg-pull-8 {
    right: 66.66666667%;
  }
  .ic-col-lg-pull-7 {
    right: 58.33333333%;
  }
  .ic-col-lg-pull-6 {
    right: 50%;
  }
  .ic-col-lg-pull-5 {
    right: 41.66666667%;
  }
  .ic-col-lg-pull-4 {
    right: 33.33333333%;
  }
  .ic-col-lg-pull-3 {
    right: 25%;
  }
  .ic-col-lg-pull-2 {
    right: 16.66666667%;
  }
  .ic-col-lg-pull-1 {
    right: 8.33333333%;
  }
  .ic-col-lg-pull-0 {
    right: auto;
  }
  .ic-col-lg-push-12 {
    left: 100%;
  }
  .ic-col-lg-push-11 {
    left: 91.66666667%;
  }
  .ic-col-lg-push-10 {
    left: 83.33333333%;
  }
  .ic-col-lg-push-9 {
    left: 75%;
  }
  .ic-col-lg-push-8 {
    left: 66.66666667%;
  }
  .ic-col-lg-push-7 {
    left: 58.33333333%;
  }
  .ic-col-lg-push-6 {
    left: 50%;
  }
  .ic-col-lg-push-5 {
    left: 41.66666667%;
  }
  .ic-col-lg-push-4 {
    left: 33.33333333%;
  }
  .ic-col-lg-push-3 {
    left: 25%;
  }
  .ic-col-lg-push-2 {
    left: 16.66666667%;
  }
  .ic-col-lg-push-1 {
    left: 8.33333333%;
  }
  .ic-col-lg-push-0 {
    left: auto;
  }
  .ic-col-lg-offset-12 {
    margin-left: 100%;
  }
  .ic-col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .ic-col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .ic-col-lg-offset-9 {
    margin-left: 75%;
  }
  .ic-col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .ic-col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .ic-col-lg-offset-6 {
    margin-left: 50%;
  }
  .ic-col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .ic-col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .ic-col-lg-offset-3 {
    margin-left: 25%;
  }
  .ic-col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .ic-col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .ic-col-lg-offset-0 {
    margin-left: 0%;
  }
}

table {
  background-color: transparent;
}

th {
  text-align: left;
}

.ic-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
  > {
    thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
      padding: 8px;
      line-height: 1.42857143;
      vertical-align: top;
      border-top: 1px solid #dddddd;
    }
    thead > tr > th {
      vertical-align: bottom;
      border-bottom: 2px solid #dddddd;
    }
    caption + thead > tr:first-child > th, colgroup + thead > tr:first-child > th, thead:first-child > tr:first-child > th, caption + thead > tr:first-child > td, colgroup + thead > tr:first-child > td, thead:first-child > tr:first-child > td {
      border-top: 0;
    }
    tbody + tbody {
      border-top: 2px solid #dddddd;
    }
  }
  .ic-table {
    background-color: #ffffff;
  }
}

.ic-table-condensed > {
  thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
    padding: 5px;
  }
}

.ic-table-bordered {
  border: 1px solid #dddddd;
  > {
    thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
      border: 1px solid #dddddd;
    }
    thead > tr > {
      th, td {
        border-bottom-width: 2px;
      }
    }
  }
}

.ic-table-striped > tbody > tr:nth-child(odd) > {
  td, th {
    background-color: #f9f9f9;
  }
}

.ic-table-hover > tbody > tr:hover > {
  td, th {
    background-color: #f5f5f5;
  }
}

table {
  col[class*="col-"] {
    position: static;
    float: none;
    display: table-column;
  }
  td[class*="col-"], th[class*="col-"] {
    position: static;
    float: none;
    display: table-cell;
  }
}

.ic-table > {
  thead > tr > td.active, tbody > tr > td.active, tfoot > tr > td.active, thead > tr > th.active, tbody > tr > th.active, tfoot > tr > th.active, thead > tr.active > td, tbody > tr.active > td, tfoot > tr.active > td, thead > tr.active > th, tbody > tr.active > th, tfoot > tr.active > th {
    background-color: #f5f5f5;
  }
}

.ic-table-hover > tbody > tr {
  > {
    td.active:hover, th.active:hover {
      background-color: #e8e8e8;
    }
  }
  &.active:hover > td, &:hover > .active, &.active:hover > th {
    background-color: #e8e8e8;
  }
}

.ic-table > {
  thead > tr > td.success, tbody > tr > td.success, tfoot > tr > td.success, thead > tr > th.success, tbody > tr > th.success, tfoot > tr > th.success, thead > tr.success > td, tbody > tr.success > td, tfoot > tr.success > td, thead > tr.success > th, tbody > tr.success > th, tfoot > tr.success > th {
    background-color: #dff0d8;
  }
}

.ic-table-hover > tbody > tr {
  > {
    td.success:hover, th.success:hover {
      background-color: #d0e9c6;
    }
  }
  &.success:hover > td, &:hover > .success, &.success:hover > th {
    background-color: #d0e9c6;
  }
}

.ic-table > {
  thead > tr > td.info, tbody > tr > td.info, tfoot > tr > td.info, thead > tr > th.info, tbody > tr > th.info, tfoot > tr > th.info, thead > tr.info > td, tbody > tr.info > td, tfoot > tr.info > td, thead > tr.info > th, tbody > tr.info > th, tfoot > tr.info > th {
    background-color: #d9edf7;
  }
}

.ic-table-hover > tbody > tr {
  > {
    td.info:hover, th.info:hover {
      background-color: #c4e3f3;
    }
  }
  &.info:hover > td, &:hover > .info, &.info:hover > th {
    background-color: #c4e3f3;
  }
}

.ic-table > {
  thead > tr > td.warning, tbody > tr > td.warning, tfoot > tr > td.warning, thead > tr > th.warning, tbody > tr > th.warning, tfoot > tr > th.warning, thead > tr.warning > td, tbody > tr.warning > td, tfoot > tr.warning > td, thead > tr.warning > th, tbody > tr.warning > th, tfoot > tr.warning > th {
    background-color: #fcf8e3;
  }
}

.ic-table-hover > tbody > tr {
  > {
    td.warning:hover, th.warning:hover {
      background-color: #faf2cc;
    }
  }
  &.warning:hover > td, &:hover > .warning, &.warning:hover > th {
    background-color: #faf2cc;
  }
}

.ic-table > {
  thead > tr > td.danger, tbody > tr > td.danger, tfoot > tr > td.danger, thead > tr > th.danger, tbody > tr > th.danger, tfoot > tr > th.danger, thead > tr.danger > td, tbody > tr.danger > td, tfoot > tr.danger > td, thead > tr.danger > th, tbody > tr.danger > th, tfoot > tr.danger > th {
    background-color: #f2dede;
  }
}

.ic-table-hover > tbody > tr {
  > {
    td.danger:hover, th.danger:hover {
      background-color: #ebcccc;
    }
  }
  &.danger:hover > td, &:hover > .danger, &.danger:hover > th {
    background-color: #ebcccc;
  }
}

@media screen and (max-width: 767px) {
  .ic-table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    overflow-x: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #dddddd;
    -webkit-overflow-scrolling: touch;
    > {
      .ic-table {
        margin-bottom: 0;
        > {
          thead > tr > th, tbody > tr > th, tfoot > tr > th, thead > tr > td, tbody > tr > td, tfoot > tr > td {
            white-space: nowrap;
          }
        }
      }
      .ic-table-bordered {
        border: 0;
        > {
          thead > tr > th:first-child, tbody > tr > th:first-child, tfoot > tr > th:first-child, thead > tr > td:first-child, tbody > tr > td:first-child, tfoot > tr > td:first-child {
            border-left: 0;
          }
          thead > tr > th:last-child, tbody > tr > th:last-child, tfoot > tr > th:last-child, thead > tr > td:last-child, tbody > tr > td:last-child, tfoot > tr > td:last-child {
            border-right: 0;
          }
          tbody > tr:last-child > th, tfoot > tr:last-child > th, tbody > tr:last-child > td, tfoot > tr:last-child > td {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

fieldset {
  padding: 0;
  margin: 0;
  border: 0;
  min-width: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}

input {
  &[type="search"] {
    box-sizing: border-box;
  }
  &[type="radio"], &[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
  }
  &[type="file"] {
    display: block;
  }
  &[type="range"] {
    display: block;
    width: 100%;
  }
}

select {
  &[multiple], &[size] {
    height: auto;
  }
}

input {
  &[type="file"]:focus, &[type="radio"]:focus, &[type="checkbox"]:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
}

.ic-form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #ffffff;
  background-image: none;
  border: 1px solid #cccccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  &:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  }
  &::placeholder {
    color: #777777;
    opacity: 1;
  }
  &[disabled], &[readonly] {
    cursor: not-allowed;
    background-color: #eeeeee;
    opacity: 1;
  }
}

fieldset[disabled] .ic-form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}

textarea.ic-form-control {
  height: auto;
}

input {
  &[type="search"] {
    -webkit-appearance: none;
  }
  &[type="date"], &[type="time"], &[type="datetime-local"], &[type="month"] {
    line-height: 1.42857143;
  }
  &[type="date"].ic-input-sm, &[type="time"].ic-input-sm, &[type="datetime-local"].ic-input-sm, &[type="month"].ic-input-sm {
    line-height: 30px;
  }
  &[type="date"].ic-input-lg, &[type="time"].ic-input-lg, &[type="datetime-local"].ic-input-lg, &[type="month"].ic-input-lg {
    line-height: 46px;
  }
}

.ic-form-group {
  margin-bottom: 15px;
}

.ic-radio, .ic-checkbox {
  position: relative;
  display: block;
  min-height: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ic-radio label, .ic-checkbox label {
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: normal;
  cursor: pointer;
}

.ic-radio input[type="radio"], .ic-radio-inline input[type="radio"], .ic-checkbox input[type="checkbox"], .ic-checkbox-inline input[type="checkbox"] {
  position: absolute;
  margin-left: -20px;
  margin-top: 4px \9;
}

.ic-radio + .ic-radio, .ic-checkbox + .ic-checkbox {
  margin-top: -5px;
}

.ic-radio-inline, .ic-checkbox-inline {
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  vertical-align: middle;
  font-weight: normal;
  cursor: pointer;
}

.ic-radio-inline + .ic-radio-inline, .ic-checkbox-inline + .ic-checkbox-inline {
  margin-top: 0;
  margin-left: 10px;
}

input {
  &[type="radio"][disabled], &[type="checkbox"][disabled], &[type="radio"].ic-disabled, &[type="checkbox"].ic-disabled {
    cursor: not-allowed;
  }
}

fieldset[disabled] input {
  &[type="radio"], &[type="checkbox"] {
    cursor: not-allowed;
  }
}

.ic-radio-inline.ic-disabled, .ic-checkbox-inline.ic-disabled {
  cursor: not-allowed;
}

fieldset[disabled] {
  .ic-radio-inline, .ic-checkbox-inline {
    cursor: not-allowed;
  }
}

.ic-radio.ic-disabled label, .ic-checkbox.ic-disabled label {
  cursor: not-allowed;
}

fieldset[disabled] {
  .ic-radio label, .ic-checkbox label {
    cursor: not-allowed;
  }
}

.ic-form-control-static {
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
  &.ic-input-lg, &.ic-input-sm {
    padding-left: 0;
    padding-right: 0;
  }
}

.ic-input-sm, .ic-form-horizontal .ic-form-group-sm .ic-form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.ic-input-sm {
  height: 30px;
  line-height: 30px;
}

textarea.ic-input-sm, select[multiple].ic-input-sm {
  height: auto;
}

.ic-input-lg, .ic-form-horizontal .ic-form-group-lg .ic-form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

select.ic-input-lg {
  height: 46px;
  line-height: 46px;
}

textarea.ic-input-lg, select[multiple].ic-input-lg {
  height: auto;
}

.ic-has-feedback {
  position: relative;
  .ic-form-control {
    padding-right: 42.5px;
  }
}

.ic-form-control-feedback {
  position: absolute;
  top: 25px;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
}

.ic-input-lg + .ic-form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.ic-input-sm + .ic-form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.ic-has-success {
  .ic-help-block, .ic-control-label, .ic-radio, .ic-checkbox, .ic-radio-inline, .ic-checkbox-inline {
    color: #3c763d;
  }
  .ic-form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #2b542c;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
    }
  }
  .ic-input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8;
  }
  .ic-form-control-feedback {
    color: #3c763d;
  }
}

.ic-has-warning {
  .ic-help-block, .ic-control-label, .ic-radio, .ic-checkbox, .ic-radio-inline, .ic-checkbox-inline {
    color: #8a6d3b;
  }
  .ic-form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #66512c;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
    }
  }
  .ic-input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3;
  }
  .ic-form-control-feedback {
    color: #8a6d3b;
  }
}

.ic-has-error {
  .ic-help-block, .ic-control-label, .ic-radio, .ic-checkbox, .ic-radio-inline, .ic-checkbox-inline {
    color: #a94442;
  }
  .ic-form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    &:focus {
      border-color: #843534;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
    }
  }
  .ic-input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede;
  }
  .ic-form-control-feedback {
    color: #a94442;
  }
}

.ic-has-feedback label.ic-sr-only ~ .ic-form-control-feedback {
  top: 0;
}

.ic-help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .ic-form-inline {
    .ic-form-group {
      display: inline-block;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .ic-form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle;
    }
    .ic-input-group {
      display: inline-table;
      vertical-align: middle;
      .ic-input-group-addon, .ic-input-group-btn, .ic-form-control {
        width: auto;
      }
      > .ic-form-control {
        width: 100%;
      }
    }
    .ic-control-label {
      margin-bottom: 0;
      vertical-align: middle;
    }
    .ic-radio, .ic-checkbox {
      display: inline-block;
      margin-top: 0;
      margin-bottom: 0;
      vertical-align: middle;
    }
    .ic-radio label, .ic-checkbox label {
      padding-left: 0;
    }
    .ic-radio input[type="radio"], .ic-checkbox input[type="checkbox"] {
      position: relative;
      margin-left: 0;
    }
    .ic-has-feedback .ic-form-control-feedback {
      top: 0;
    }
  }
}

.ic-form-horizontal {
  .ic-radio, .ic-checkbox, .ic-radio-inline, .ic-checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px;
  }
  .ic-radio, .ic-checkbox {
    min-height: 27px;
  }
  .ic-form-group {
    margin-left: -15px;
    margin-right: -15px;
  }
  .ic-has-feedback .ic-form-control-feedback {
    top: 0;
    right: 15px;
  }
}

@media (min-width: 768px) {
  .ic-form-horizontal .ic-control-label {
    text-align: right;
    margin-bottom: 0;
    padding-top: 7px;
  }
}

@media (min-width: 768px) {
  .ic-form-horizontal .ic-form-group-lg .ic-control-label {
    padding-top: 14.3px;
  }
}

@media (min-width: 768px) {
  .ic-form-horizontal .ic-form-group-sm .ic-control-label {
    padding-top: 6px;
  }
}

.ic-btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  border-radius: 4px;
  user-select: none;
  &:focus, &:active:focus, &.ic-active:focus {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }
  &:hover, &:focus {
    color: #000000;
    text-decoration: none;
  }
  &:active, &.ic-active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  &.ic-disabled, &[disabled] {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.65;
    filter: alpha(opacity = 65);
    box-shadow: none;
  }
}

fieldset[disabled] .ic-btn {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.65;
  filter: alpha(opacity = 65);
  box-shadow: none;
}

.ic-btn-default {
  color: #000000;
  background-color: #ffffff;
  border-color: #cccccc;
  &:hover, &:focus, &:active, &.ic-active {
    color: #000000;
    background-color: #e6e6e6;
    border-color: #adadad;
  }
}

.open > .dropdown-toggle.ic-btn-default {
  color: #000000;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.ic-btn-default {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-default {
  background-image: none;
}

.ic-btn-default {
  &.ic-disabled, &[disabled] {
    background-color: #ffffff;
    border-color: #cccccc;
  }
}

fieldset[disabled] .ic-btn-default {
  background-color: #ffffff;
  border-color: #cccccc;
}

.ic-btn-default {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #ffffff;
    border-color: #cccccc;
  }
}

fieldset[disabled] .ic-btn-default:hover {
  background-color: #ffffff;
  border-color: #cccccc;
}

.ic-btn-default {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #ffffff;
    border-color: #cccccc;
  }
}

fieldset[disabled] .ic-btn-default:focus {
  background-color: #ffffff;
  border-color: #cccccc;
}

.ic-btn-default {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #ffffff;
    border-color: #cccccc;
  }
}

fieldset[disabled] .ic-btn-default:active {
  background-color: #ffffff;
  border-color: #cccccc;
}

.ic-btn-default {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #ffffff;
    border-color: #cccccc;
  }
}

fieldset[disabled] .ic-btn-default.ic-active {
  background-color: #ffffff;
  border-color: #cccccc;
}

.ic-btn-default .ic-badge {
  color: #ffffff;
  background-color: #000000;
}

.ic-btn-primary {
  color: #ffffff;
  background-color: #428bca;
  border-color: #357ebd;
  &:hover, &:focus, &:active, &.ic-active {
    color: #ffffff;
    background-color: #3071a9;
    border-color: #285e8e;
  }
}

.open > .dropdown-toggle.ic-btn-primary {
  color: #ffffff;
  background-color: #3071a9;
  border-color: #285e8e;
}

.ic-btn-primary {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-primary {
  background-image: none;
}

.ic-btn-primary {
  &.ic-disabled, &[disabled] {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .ic-btn-primary {
  background-color: #428bca;
  border-color: #357ebd;
}

.ic-btn-primary {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .ic-btn-primary:hover {
  background-color: #428bca;
  border-color: #357ebd;
}

.ic-btn-primary {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .ic-btn-primary:focus {
  background-color: #428bca;
  border-color: #357ebd;
}

.ic-btn-primary {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .ic-btn-primary:active {
  background-color: #428bca;
  border-color: #357ebd;
}

.ic-btn-primary {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #428bca;
    border-color: #357ebd;
  }
}

fieldset[disabled] .ic-btn-primary.ic-active {
  background-color: #428bca;
  border-color: #357ebd;
}

.ic-btn-primary .ic-badge {
  color: #428bca;
  background-color: #ffffff;
}

.ic-btn-success {
  color: #ffffff;
  background-color: #5cb85c;
  border-color: #4cae4c;
  &:hover, &:focus, &:active, &.ic-active {
    color: #ffffff;
    background-color: #449d44;
    border-color: #398439;
  }
}

.open > .dropdown-toggle.ic-btn-success {
  color: #ffffff;
  background-color: #449d44;
  border-color: #398439;
}

.ic-btn-success {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-success {
  background-image: none;
}

.ic-btn-success {
  &.ic-disabled, &[disabled] {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .ic-btn-success {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ic-btn-success {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .ic-btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ic-btn-success {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .ic-btn-success:focus {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ic-btn-success {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .ic-btn-success:active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ic-btn-success {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #5cb85c;
    border-color: #4cae4c;
  }
}

fieldset[disabled] .ic-btn-success.ic-active {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.ic-btn-success .ic-badge {
  color: #5cb85c;
  background-color: #ffffff;
}

.ic-btn-info {
  color: #ffffff;
  background-color: #5bc0de;
  border-color: #46b8da;
  &:hover, &:focus, &:active, &.ic-active {
    color: #ffffff;
    background-color: #31b0d5;
    border-color: #269abc;
  }
}

.open > .dropdown-toggle.ic-btn-info {
  color: #ffffff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.ic-btn-info {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-info {
  background-image: none;
}

.ic-btn-info {
  &.ic-disabled, &[disabled] {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .ic-btn-info {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ic-btn-info {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .ic-btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ic-btn-info {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .ic-btn-info:focus {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ic-btn-info {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .ic-btn-info:active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ic-btn-info {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #5bc0de;
    border-color: #46b8da;
  }
}

fieldset[disabled] .ic-btn-info.ic-active {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.ic-btn-info .ic-badge {
  color: #5bc0de;
  background-color: #ffffff;
}

.ic-btn-warning {
  color: #ffffff;
  background-color: #f0ad4e;
  border-color: #eea236;
  &:hover, &:focus, &:active, &.ic-active {
    color: #ffffff;
    background-color: #ec971f;
    border-color: #d58512;
  }
}

.open > .dropdown-toggle.ic-btn-warning {
  color: #ffffff;
  background-color: #ec971f;
  border-color: #d58512;
}

.ic-btn-warning {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-warning {
  background-image: none;
}

.ic-btn-warning {
  &.ic-disabled, &[disabled] {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .ic-btn-warning {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ic-btn-warning {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .ic-btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ic-btn-warning {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .ic-btn-warning:focus {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ic-btn-warning {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .ic-btn-warning:active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ic-btn-warning {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #f0ad4e;
    border-color: #eea236;
  }
}

fieldset[disabled] .ic-btn-warning.ic-active {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.ic-btn-warning .ic-badge {
  color: #f0ad4e;
  background-color: #ffffff;
}

.ic-btn-danger {
  color: #ffffff;
  background-color: #d9534f;
  border-color: #d43f3a;
  &:hover, &:focus, &:active, &.ic-active {
    color: #ffffff;
    background-color: #c9302c;
    border-color: #ac2925;
  }
}

.open > .dropdown-toggle.ic-btn-danger {
  color: #ffffff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.ic-btn-danger {
  &:active, &.ic-active {
    background-image: none;
  }
}

.open > .dropdown-toggle.ic-btn-danger {
  background-image: none;
}

.ic-btn-danger {
  &.ic-disabled, &[disabled] {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .ic-btn-danger {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ic-btn-danger {
  &.ic-disabled:hover, &[disabled]:hover {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .ic-btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ic-btn-danger {
  &.ic-disabled:focus, &[disabled]:focus {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .ic-btn-danger:focus {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ic-btn-danger {
  &.ic-disabled:active, &[disabled]:active {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .ic-btn-danger:active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ic-btn-danger {
  &.ic-disabled.ic-active, &[disabled].ic-active {
    background-color: #d9534f;
    border-color: #d43f3a;
  }
}

fieldset[disabled] .ic-btn-danger.ic-active {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.ic-btn-danger .ic-badge {
  color: #d9534f;
  background-color: #ffffff;
}

.ic-btn-link {
  color: #428bca;
  font-weight: normal;
  cursor: pointer;
  border-radius: 0;
  background-color: transparent;
  box-shadow: none;
  &:active, &[disabled] {
    background-color: transparent;
    box-shadow: none;
  }
}

fieldset[disabled] .ic-btn-link {
  background-color: transparent;
  box-shadow: none;
}

.ic-btn-link {
  border-color: transparent;
  &:hover, &:focus, &:active {
    border-color: transparent;
  }
  &:hover, &:focus {
    color: #2a6496;
    text-decoration: underline;
    background-color: transparent;
  }
  &[disabled]:hover {
    color: #777777;
    text-decoration: none;
  }
}

fieldset[disabled] .ic-btn-link:hover, .ic-btn-link[disabled]:focus, fieldset[disabled] .ic-btn-link:focus {
  color: #777777;
  text-decoration: none;
}

.ic-btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.33;
  border-radius: 6px;
}

.ic-btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.ic-btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.ic-btn-block {
  display: block;
  width: 100%;
  + .ic-btn-block {
    margin-top: 5px;
  }
}

input {
  &[type="submit"].ic-btn-block, &[type="reset"].ic-btn-block, &[type="button"].ic-btn-block {
    width: 100%;
  }
}

/* BOOTSTRAP JAVASCRIPT COMPONENTS  - NO PREFIX  */

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;
  &.in {
    display: block;
  }
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
  &.pull-right {
    right: 0;
    left: auto;
  }
  .divider {
    height: 1px;
    margin: 9px 0;
    overflow: hidden;
    background-color: #e5e5e5;
  }
  > {
    li > a {
      display: block;
      padding: 3px 20px;
      clear: both;
      font-weight: normal;
      line-height: 1.42857143;
      color: #333333;
      white-space: nowrap;
      &:hover, &:focus {
        text-decoration: none;
        color: #262626;
        background-color: #f5f5f5;
      }
    }
    .active > a {
      color: #ffffff;
      text-decoration: none;
      outline: 0;
      background-color: #428bca;
      &:hover, &:focus {
        color: #ffffff;
        text-decoration: none;
        outline: 0;
        background-color: #428bca;
      }
    }
    .disabled > a {
      color: #777777;
      &:hover, &:focus {
        color: #777777;
      }
      &:hover, &:focus {
        text-decoration: none;
        background-color: transparent;
        background-image: none;
        filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
        cursor: not-allowed;
      }
    }
  }
}

.open > {
  .dropdown-menu {
    display: block;
  }
  a {
    outline: 0;
  }
}

.dropdown-menu-right {
  left: auto;
  right: 0;
}

.dropdown-menu-left {
  left: 0;
  right: auto;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 990;
}

.pull-right > .dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret, .navbar-fixed-bottom .dropdown .caret {
  border-top: 0;
  border-bottom: 4px solid;
  content: "";
}

.dropup .dropdown-menu, .navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 1px;
}

@media (min-width: 768px) {
  .navbar-right {
    .dropdown-menu {
      left: auto;
      right: 0;
    }
    .dropdown-menu-left {
      left: 0;
      right: auto;
    }
  }
}

.ic-input-group {
  position: relative;
  display: table;
  border-collapse: separate;
  &[class*="col-"] {
    float: none;
    padding-left: 0;
    padding-right: 0;
  }
  .ic-form-control {
    position: relative;
    z-index: 2;
    float: left;
    width: 100%;
    margin-bottom: 0;
  }
}

.ic-input-group-lg > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33;
    border-radius: 6px;
  }
}

select.ic-input-group-lg > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: 46px;
    line-height: 46px;
  }
}

textarea.ic-input-group-lg > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: auto;
  }
}

select[multiple].ic-input-group-lg > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: auto;
  }
}

.ic-input-group-sm > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
  }
}

select.ic-input-group-sm > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: 30px;
    line-height: 30px;
  }
}

textarea.ic-input-group-sm > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: auto;
  }
}

select[multiple].ic-input-group-sm > {
  .ic-form-control, .ic-input-group-addon, .ic-input-group-btn > .ic-btn {
    height: auto;
  }
}

.ic-input-group-addon, .ic-input-group-btn, .ic-input-group .ic-form-control {
  display: table-cell;
}

.ic-input-group-addon:not(:first-child):not(:last-child), .ic-input-group-btn:not(:first-child):not(:last-child), .ic-input-group .ic-form-control:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.ic-input-group-addon, .ic-input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.ic-input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1;
  color: #555555;
  text-align: center;
  background-color: #eeeeee;
  border: 1px solid #cccccc;
  border-radius: 4px;
  &.ic-input-sm {
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 3px;
  }
  &.ic-input-lg {
    padding: 10px 16px;
    font-size: 18px;
    border-radius: 6px;
  }
  input {
    &[type="radio"], &[type="checkbox"] {
      margin-top: 0;
    }
  }
}

.ic-input-group .ic-form-control:first-child, .ic-input-group-addon:first-child {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
}

.ic-input-group-btn {
  &:first-child > {
    .ic-btn, .ic-btn-group > .ic-btn, .dropdown-toggle {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
  &:last-child > {
    .ic-btn:not(:last-child):not(.dropdown-toggle), .ic-btn-group:not(:last-child) > .ic-btn {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

.ic-input-group-addon:first-child {
  border-right: 0;
}

.ic-input-group .ic-form-control:last-child, .ic-input-group-addon:last-child {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
}

.ic-input-group-btn {
  &:last-child > {
    .ic-btn, .ic-btn-group > .ic-btn, .dropdown-toggle {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
  &:first-child > {
    .ic-btn:not(:first-child), .ic-btn-group:not(:first-child) > .ic-btn {
      border-bottom-left-radius: 0;
      border-top-left-radius: 0;
    }
  }
}

.ic-input-group-addon:last-child {
  border-left: 0;
}

.ic-input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
  > .ic-btn {
    position: relative;
    + .ic-btn {
      margin-left: -1px;
    }
    &:hover, &:focus, &:active {
      z-index: 2;
    }
  }
  &:first-child > {
    .ic-btn, .ic-btn-group {
      margin-right: -1px;
    }
  }
  &:last-child > {
    .ic-btn, .ic-btn-group {
      margin-left: -1px;
    }
  }
}

.ic-pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
  > {
    li {
      display: inline;
      > {
        a, span {
          position: relative;
          float: left;
          padding: 6px 12px;
          line-height: 1.42857143;
          text-decoration: none;
          color: #428bca;
          background-color: #ffffff;
          border: 1px solid #dddddd;
          margin-left: -1px;
        }
      }
      &:first-child > {
        a, span {
          margin-left: 0;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
      }
      &:last-child > {
        a, span {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
        }
      }
      > {
        a:hover, span:hover, a:focus, span:focus {
          color: #2a6496;
          background-color: #eeeeee;
          border-color: #dddddd;
        }
      }
    }
    .ic-active > {
      a, span, a:hover, span:hover, a:focus, span:focus {
        z-index: 2;
        color: #ffffff;
        background-color: #428bca;
        border-color: #428bca;
        cursor: default;
      }
    }
    .ic-disabled > {
      span {
        color: #777777;
        background-color: #ffffff;
        border-color: #dddddd;
        cursor: not-allowed;
        &:hover, &:focus {
          color: #777777;
          background-color: #ffffff;
          border-color: #dddddd;
          cursor: not-allowed;
        }
      }
      a {
        color: #777777;
        background-color: #ffffff;
        border-color: #dddddd;
        cursor: not-allowed;
        &:hover, &:focus {
          color: #777777;
          background-color: #ffffff;
          border-color: #dddddd;
          cursor: not-allowed;
        }
      }
    }
  }
}

.ic-pagination-lg > li {
  > {
    a, span {
      padding: 10px 16px;
      font-size: 18px;
    }
  }
  &:first-child > {
    a, span {
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
    }
  }
  &:last-child > {
    a, span {
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
    }
  }
}

.ic-pagination-sm > li {
  > {
    a, span {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
  &:first-child > {
    a, span {
      border-bottom-left-radius: 3px;
      border-top-left-radius: 3px;
    }
  }
  &:last-child > {
    a, span {
      border-bottom-right-radius: 3px;
      border-top-right-radius: 3px;
    }
  }
}

.ic-pager {
  padding-left: 0;
  margin: 20px 0;
  list-style: none;
  text-align: center;
  li {
    display: inline;
    > {
      a, span {
        display: inline-block;
        padding: 5px 14px;
        background-color: #ffffff;
        border: 1px solid #dddddd;
        border-radius: 15px;
      }
      a {
        &:hover, &:focus {
          text-decoration: none;
          background-color: #eeeeee;
        }
      }
    }
  }
  .ic-next > {
    a, span {
      float: right;
    }
  }
  .ic-previous > {
    a, span {
      float: left;
    }
  }
  .ic-disabled > {
    a {
      color: #777777;
      background-color: #ffffff;
      cursor: not-allowed;
      &:hover, &:focus {
        color: #777777;
        background-color: #ffffff;
        cursor: not-allowed;
      }
    }
    span {
      color: #777777;
      background-color: #ffffff;
      cursor: not-allowed;
    }
  }
}

.ic-label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 75%;
  font-weight: bold;
  line-height: 1;
  color: #ffffff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}

a.ic-label {
  &:hover, &:focus {
    color: #ffffff;
    text-decoration: none;
    cursor: pointer;
  }
}

.ic-label:empty {
  display: none;
}

.ic-btn .ic-label {
  position: relative;
  top: -1px;
}

.ic-label-default {
  background-color: #777777;
  &[href] {
    &:hover, &:focus {
      background-color: #5e5e5e;
    }
  }
}

.ic-label-primary {
  background-color: #428bca;
  &[href] {
    &:hover, &:focus {
      background-color: #3071a9;
    }
  }
}

.ic-label-success {
  background-color: #5cb85c;
  &[href] {
    &:hover, &:focus {
      background-color: #449d44;
    }
  }
}

.ic-label-info {
  background-color: #5bc0de;
  &[href] {
    &:hover, &:focus {
      background-color: #31b0d5;
    }
  }
}

.ic-label-warning {
  background-color: #f0ad4e;
  &[href] {
    &:hover, &:focus {
      background-color: #ec971f;
    }
  }
}

.ic-label-danger {
  background-color: #d9534f;
  &[href] {
    &:hover, &:focus {
      background-color: #c9302c;
    }
  }
}

.ic-badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  line-height: 1;
  vertical-align: baseline;
  white-space: nowrap;
  text-align: center;
  background-color: #777777;
  border-radius: 10px;
  &:empty {
    display: none;
  }
}

.ic-btn .ic-badge {
  position: relative;
  top: -1px;
}

.ic-btn-xs .ic-badge {
  top: 0;
  padding: 1px 5px;
}

a {
  &.ic-badge {
    &:hover, &:focus {
      color: #ffffff;
      text-decoration: none;
      cursor: pointer;
    }
  }
  &.ic-list-group-item.ic-active > .ic-badge {
    color: #428bca;
    background-color: #ffffff;
  }
}

.ic-nav-pills > {
  .ic-active > a > .ic-badge {
    color: #428bca;
    background-color: #ffffff;
  }
  li > a > .ic-badge {
    margin-left: 3px;
  }
}

.ic-thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  border-radius: 4px;
  transition: all 0.2s ease-in-out;
  > img, a > img {
    margin-left: auto;
    margin-right: auto;
  }
}

a.ic-thumbnail {
  &:hover, &:focus, &.ic-active {
    border-color: #428bca;
  }
}

.ic-thumbnail .ic-caption {
  padding: 9px;
  color: #333333;
}

.ic-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
  h4 {
    margin-top: 0;
    color: inherit;
  }
  .ic-alert-link {
    font-weight: bold;
  }
  > {
    p, ul {
      margin-bottom: 0;
    }
    p + p {
      margin-top: 5px;
    }
  }
}

.ic-alert-dismissable, .ic-alert-dismissible {
  padding-right: 35px;
}

.ic-alert-dismissable .close, .ic-alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.ic-alert-success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #3c763d;
  hr {
    border-top-color: #c9e2b3;
  }
  .ic-alert-link {
    color: #2b542c;
  }
}

.ic-alert-info {
  background-color: #d9edf7;
  border-color: #bce8f1;
  color: #31708f;
  hr {
    border-top-color: #a6e1ec;
  }
  .ic-alert-link {
    color: #245269;
  }
}

.ic-alert-warning {
  background-color: #fcf8e3;
  border-color: #faebcc;
  color: #8a6d3b;
  hr {
    border-top-color: #f7e1b5;
  }
  .ic-alert-link {
    color: #66512c;
  }
}

.ic-alert-danger {
  background-color: #f2dede;
  border-color: #ebccd1;
  color: #a94442;
  hr {
    border-top-color: #e4b9c0;
  }
  .ic-alert-link {
    color: #843534;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.ic-progress {
  overflow: hidden;
  height: 20px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border-radius: 4px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.ic-progress-bar {
  float: left;
  width: 0%;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #ffffff;
  text-align: center;
  background-color: #428bca;
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 0.6s ease;
}

.ic-progress-striped .ic-progress-bar, .ic-progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 40px 40px;
}

.ic-progress.ic-active .ic-progress-bar {
  animation: progress-bar-stripes 2s linear infinite;
}

.ic-progress-bar {
  &.ic-active {
    animation: progress-bar-stripes 2s linear infinite;
  }
  &[aria-valuenow="1"], &[aria-valuenow="2"] {
    min-width: 30px;
  }
  &[aria-valuenow="0"] {
    color: #777777;
    min-width: 30px;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
  }
}

.ic-progress-bar-success {
  background-color: #5cb85c;
}

.ic-progress-striped .ic-progress-bar-success {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.ic-progress-bar-info {
  background-color: #5bc0de;
}

.ic-progress-striped .ic-progress-bar-info {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.ic-progress-bar-warning {
  background-color: #f0ad4e;
}

.ic-progress-striped .ic-progress-bar-warning {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.ic-progress-bar-danger {
  background-color: #d9534f;
}

.ic-progress-striped .ic-progress-bar-danger {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}

.ic-list-group {
  margin-bottom: 20px;
  padding-left: 0;
}

.ic-list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #ffffff;
  border: 1px solid #dddddd;
  &:first-child {
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
  }
  &:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  > .ic-badge {
    float: right;
    + .ic-badge {
      margin-right: 5px;
    }
  }
}

a.ic-list-group-item {
  color: #555555;
  .ic-list-group-item-heading {
    color: #333333;
  }
  &:hover, &:focus {
    text-decoration: none;
    color: #555555;
    background-color: #f5f5f5;
  }
}

.ic-list-group-item {
  &.ic-disabled {
    background-color: #eeeeee;
    color: #777777;
    &:hover, &:focus {
      background-color: #eeeeee;
      color: #777777;
    }
    .ic-list-group-item-heading, &:hover .ic-list-group-item-heading, &:focus .ic-list-group-item-heading {
      color: inherit;
    }
    .ic-list-group-item-text, &:hover .ic-list-group-item-text, &:focus .ic-list-group-item-text {
      color: #777777;
    }
  }
  &.ic-active {
    z-index: 2;
    color: #ffffff;
    background-color: #428bca;
    border-color: #428bca;
    &:hover, &:focus {
      z-index: 2;
      color: #ffffff;
      background-color: #428bca;
      border-color: #428bca;
    }
    .ic-list-group-item-heading, &:hover .ic-list-group-item-heading, &:focus .ic-list-group-item-heading, .ic-list-group-item-heading > small, &:hover .ic-list-group-item-heading > small, &:focus .ic-list-group-item-heading > small, .ic-list-group-item-heading > .ic-small, &:hover .ic-list-group-item-heading > .ic-small, &:focus .ic-list-group-item-heading > .ic-small {
      color: inherit;
    }
    .ic-list-group-item-text, &:hover .ic-list-group-item-text, &:focus .ic-list-group-item-text {
      color: #e1edf7;
    }
  }
}

.ic-list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.ic-list-group-item-success {
  color: #3c763d;
  .ic-list-group-item-heading {
    color: inherit;
  }
  &:hover, &:focus {
    color: #3c763d;
    background-color: #d0e9c6;
  }
  &.ic-active {
    color: #fff;
    background-color: #3c763d;
    border-color: #3c763d;
    &:hover, &:focus {
      color: #fff;
      background-color: #3c763d;
      border-color: #3c763d;
    }
  }
}

.ic-list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.ic-list-group-item-info {
  color: #31708f;
  .ic-list-group-item-heading {
    color: inherit;
  }
  &:hover, &:focus {
    color: #31708f;
    background-color: #c4e3f3;
  }
  &.ic-active {
    color: #fff;
    background-color: #31708f;
    border-color: #31708f;
    &:hover, &:focus {
      color: #fff;
      background-color: #31708f;
      border-color: #31708f;
    }
  }
}

.ic-list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.ic-list-group-item-warning {
  color: #8a6d3b;
  .ic-list-group-item-heading {
    color: inherit;
  }
  &:hover, &:focus {
    color: #8a6d3b;
    background-color: #faf2cc;
  }
  &.ic-active {
    color: #fff;
    background-color: #8a6d3b;
    border-color: #8a6d3b;
    &:hover, &:focus {
      color: #fff;
      background-color: #8a6d3b;
      border-color: #8a6d3b;
    }
  }
}

.ic-list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.ic-list-group-item-danger {
  color: #a94442;
  .ic-list-group-item-heading {
    color: inherit;
  }
  &:hover, &:focus {
    color: #a94442;
    background-color: #ebcccc;
  }
  &.ic-active {
    color: #fff;
    background-color: #a94442;
    border-color: #a94442;
    &:hover, &:focus {
      color: #fff;
      background-color: #a94442;
      border-color: #a94442;
    }
  }
}

.ic-list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.ic-list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

/* BOOTSTRAP PANELS  - NO PREFIX  */

.panel {
  margin-bottom: 20px;
  background-color: #ffffff;
  border: 1px solid transparent;
  border-radius: 4px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  > .dropdown .dropdown-toggle {
    color: inherit;
  }
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
  > a {
    color: inherit;
  }
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #dddddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel > .ic-list-group {
  margin-bottom: 0;
  .ic-list-group-item {
    border-width: 1px 0;
    border-radius: 0;
  }
  &:first-child .ic-list-group-item:first-child {
    border-top: 0;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  &:last-child .ic-list-group-item:last-child {
    border-bottom: 0;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}

.panel-heading + .ic-list-group .ic-list-group-item:first-child, .ic-list-group + .panel-footer {
  border-top-width: 0;
}

.panel > {
  .ic-table, .ic-table-responsive > .ic-table, .panel-collapse > .ic-table {
    margin-bottom: 0;
  }
  .ic-table:first-child, .ic-table-responsive:first-child > .ic-table:first-child {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
  }
  .ic-table:first-child > thead:first-child > tr:first-child td:first-child, .ic-table-responsive:first-child > .ic-table:first-child > thead:first-child > tr:first-child td:first-child, .ic-table:first-child > tbody:first-child > tr:first-child td:first-child, .ic-table-responsive:first-child > .ic-table:first-child > tbody:first-child > tr:first-child td:first-child, .ic-table:first-child > thead:first-child > tr:first-child th:first-child, .ic-table-responsive:first-child > .ic-table:first-child > thead:first-child > tr:first-child th:first-child, .ic-table:first-child > tbody:first-child > tr:first-child th:first-child, .ic-table-responsive:first-child > .ic-table:first-child > tbody:first-child > tr:first-child th:first-child {
    border-top-left-radius: 3px;
  }
  .ic-table:first-child > thead:first-child > tr:first-child td:last-child, .ic-table-responsive:first-child > .ic-table:first-child > thead:first-child > tr:first-child td:last-child, .ic-table:first-child > tbody:first-child > tr:first-child td:last-child, .ic-table-responsive:first-child > .ic-table:first-child > tbody:first-child > tr:first-child td:last-child, .ic-table:first-child > thead:first-child > tr:first-child th:last-child, .ic-table-responsive:first-child > .ic-table:first-child > thead:first-child > tr:first-child th:last-child, .ic-table:first-child > tbody:first-child > tr:first-child th:last-child, .ic-table-responsive:first-child > .ic-table:first-child > tbody:first-child > tr:first-child th:last-child {
    border-top-right-radius: 3px;
  }
  .ic-table:last-child, .ic-table-responsive:last-child > .ic-table:last-child {
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;
  }
  .ic-table:last-child > tbody:last-child > tr:last-child td:first-child, .ic-table-responsive:last-child > .ic-table:last-child > tbody:last-child > tr:last-child td:first-child, .ic-table:last-child > tfoot:last-child > tr:last-child td:first-child, .ic-table-responsive:last-child > .ic-table:last-child > tfoot:last-child > tr:last-child td:first-child, .ic-table:last-child > tbody:last-child > tr:last-child th:first-child, .ic-table-responsive:last-child > .ic-table:last-child > tbody:last-child > tr:last-child th:first-child, .ic-table:last-child > tfoot:last-child > tr:last-child th:first-child, .ic-table-responsive:last-child > .ic-table:last-child > tfoot:last-child > tr:last-child th:first-child {
    border-bottom-left-radius: 3px;
  }
  .ic-table:last-child > tbody:last-child > tr:last-child td:last-child, .ic-table-responsive:last-child > .ic-table:last-child > tbody:last-child > tr:last-child td:last-child, .ic-table:last-child > tfoot:last-child > tr:last-child td:last-child, .ic-table-responsive:last-child > .ic-table:last-child > tfoot:last-child > tr:last-child td:last-child, .ic-table:last-child > tbody:last-child > tr:last-child th:last-child, .ic-table-responsive:last-child > .ic-table:last-child > tbody:last-child > tr:last-child th:last-child, .ic-table:last-child > tfoot:last-child > tr:last-child th:last-child, .ic-table-responsive:last-child > .ic-table:last-child > tfoot:last-child > tr:last-child th:last-child {
    border-bottom-right-radius: 3px;
  }
  .panel-body + {
    .ic-table, .ic-table-responsive {
      border-top: 1px solid #dddddd;
    }
  }
  .ic-table > tbody:first-child > tr:first-child {
    th, td {
      border-top: 0;
    }
  }
  .ic-table-bordered, .ic-table-responsive > .ic-table-bordered {
    border: 0;
  }
  .ic-table-bordered > thead > tr > th:first-child, .ic-table-responsive > .ic-table-bordered > thead > tr > th:first-child, .ic-table-bordered > tbody > tr > th:first-child, .ic-table-responsive > .ic-table-bordered > tbody > tr > th:first-child, .ic-table-bordered > tfoot > tr > th:first-child, .ic-table-responsive > .ic-table-bordered > tfoot > tr > th:first-child, .ic-table-bordered > thead > tr > td:first-child, .ic-table-responsive > .ic-table-bordered > thead > tr > td:first-child, .ic-table-bordered > tbody > tr > td:first-child, .ic-table-responsive > .ic-table-bordered > tbody > tr > td:first-child, .ic-table-bordered > tfoot > tr > td:first-child, .ic-table-responsive > .ic-table-bordered > tfoot > tr > td:first-child {
    border-left: 0;
  }
  .ic-table-bordered > thead > tr > th:last-child, .ic-table-responsive > .ic-table-bordered > thead > tr > th:last-child, .ic-table-bordered > tbody > tr > th:last-child, .ic-table-responsive > .ic-table-bordered > tbody > tr > th:last-child, .ic-table-bordered > tfoot > tr > th:last-child, .ic-table-responsive > .ic-table-bordered > tfoot > tr > th:last-child, .ic-table-bordered > thead > tr > td:last-child, .ic-table-responsive > .ic-table-bordered > thead > tr > td:last-child, .ic-table-bordered > tbody > tr > td:last-child, .ic-table-responsive > .ic-table-bordered > tbody > tr > td:last-child, .ic-table-bordered > tfoot > tr > td:last-child, .ic-table-responsive > .ic-table-bordered > tfoot > tr > td:last-child {
    border-right: 0;
  }
  .ic-table-bordered > thead > tr:first-child > td, .ic-table-responsive > .ic-table-bordered > thead > tr:first-child > td, .ic-table-bordered > tbody > tr:first-child > td, .ic-table-responsive > .ic-table-bordered > tbody > tr:first-child > td, .ic-table-bordered > thead > tr:first-child > th, .ic-table-responsive > .ic-table-bordered > thead > tr:first-child > th, .ic-table-bordered > tbody > tr:first-child > th, .ic-table-responsive > .ic-table-bordered > tbody > tr:first-child > th, .ic-table-bordered > tbody > tr:last-child > td, .ic-table-responsive > .ic-table-bordered > tbody > tr:last-child > td, .ic-table-bordered > tfoot > tr:last-child > td, .ic-table-responsive > .ic-table-bordered > tfoot > tr:last-child > td, .ic-table-bordered > tbody > tr:last-child > th, .ic-table-responsive > .ic-table-bordered > tbody > tr:last-child > th, .ic-table-bordered > tfoot > tr:last-child > th {
    border-bottom: 0;
  }
  .ic-table-responsive {
    > .ic-table-bordered > tfoot > tr:last-child > th {
      border-bottom: 0;
    }
    border: 0;
    margin-bottom: 0;
  }
}

.panel-group {
  margin-bottom: 20px;
  .panel {
    margin-bottom: 0;
    border-radius: 4px;
    + .panel {
      margin-top: 5px;
    }
  }
  .panel-heading {
    border-bottom: 0;
    + .panel-collapse > .panel-body {
      border-top: 1px solid #dddddd;
    }
  }
  .panel-footer {
    border-top: 0;
    + .panel-collapse .panel-body {
      border-bottom: 1px solid #dddddd;
    }
  }
}

.panel-default {
  border-color: #dddddd;
  > {
    .panel-heading {
      color: #333333;
      background-color: #f5f5f5;
      border-color: #dddddd;
      + .panel-collapse > .panel-body {
        border-top-color: #dddddd;
      }
      .ic-badge {
        color: #f5f5f5;
        background-color: #333333;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #dddddd;
    }
  }
}

.panel-primary {
  border-color: #428bca;
  > {
    .panel-heading {
      color: #ffffff;
      background-color: #428bca;
      border-color: #428bca;
      + .panel-collapse > .panel-body {
        border-top-color: #428bca;
      }
      .ic-badge {
        color: #428bca;
        background-color: #ffffff;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #428bca;
    }
  }
}

.panel-success {
  border-color: #d6e9c6;
  > {
    .panel-heading {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #d6e9c6;
      + .panel-collapse > .panel-body {
        border-top-color: #d6e9c6;
      }
      .ic-badge {
        color: #dff0d8;
        background-color: #3c763d;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #d6e9c6;
    }
  }
}

.panel-info {
  border-color: #bce8f1;
  > {
    .panel-heading {
      color: #31708f;
      background-color: #d9edf7;
      border-color: #bce8f1;
      + .panel-collapse > .panel-body {
        border-top-color: #bce8f1;
      }
      .ic-badge {
        color: #d9edf7;
        background-color: #31708f;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #bce8f1;
    }
  }
}

.panel-warning {
  border-color: #faebcc;
  > {
    .panel-heading {
      color: #8a6d3b;
      background-color: #fcf8e3;
      border-color: #faebcc;
      + .panel-collapse > .panel-body {
        border-top-color: #faebcc;
      }
      .ic-badge {
        color: #fcf8e3;
        background-color: #8a6d3b;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #faebcc;
    }
  }
}

.panel-danger {
  border-color: #ebccd1;
  > {
    .panel-heading {
      color: #a94442;
      background-color: #f2dede;
      border-color: #ebccd1;
      + .panel-collapse > .panel-body {
        border-top-color: #ebccd1;
      }
      .ic-badge {
        color: #f2dede;
        background-color: #a94442;
      }
    }
    .panel-footer + .panel-collapse > .panel-body {
      border-bottom-color: #ebccd1;
    }
  }
}

.ic-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
  .ic-embed-responsive-item, iframe, embed, object {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    border: 0;
  }
  &.ic-embed-responsive-16by9 {
    padding-bottom: 56%;
  }
  &.ic-embed-responsive-4by3 {
    padding-bottom: 75%;
  }
}

.ic-well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  blockquote {
    border-color: #ddd;
    border-color: rgba(0, 0, 0, 0.15);
  }
}

.ic-well-lg {
  padding: 24px;
  border-radius: 6px;
}

.ic-well-sm {
  padding: 9px;
  border-radius: 3px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000000;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.2;
  filter: alpha(opacity = 20);
  &:hover, &:focus {
    color: #000000;
    text-decoration: none;
    cursor: pointer;
    opacity: 0.5;
    filter: alpha(opacity = 50);
  }
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.modal-open {
  overflow: hidden;
}

.modal {
  display: none;
  overflow: hidden;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  &.ic-fade .modal-dialog {
    transform: translate3d(0, -25%, 0);
    transition: transform 0.3s ease-out;
  }
  &.in.modal-dialog {
    transform: translate3d(0, 0, 0);
  }
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #ffffff;
  border: 1px solid #999999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  background-clip: padding-box;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000;
  &.ic-fade {
    opacity: 0;
    filter: alpha(opacity = 0);
  }
  &.in {
    opacity: 0.5;
    filter: alpha(opacity = 50);
  }
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
  min-height: 16.42857143px;
  .close {
    margin-top: -2px;
  }
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
  .ic-btn + .ic-btn {
    margin-left: 5px;
    margin-bottom: 0;
  }
  .ic-btn-group .ic-btn + .ic-btn {
    margin-left: -1px;
  }
  .ic-btn-block + .ic-btn-block {
    margin-left: 0;
  }
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  visibility: visible;
  font-size: 12px;
  line-height: 1.4;
  opacity: 0;
  filter: alpha(opacity = 0);
  &.in {
    opacity: 0.9;
    filter: alpha(opacity = 90);
  }
  &.top {
    margin-top: -3px;
    padding: 5px 0;
  }
  &.right {
    margin-left: 3px;
    padding: 0 5px;
  }
  &.bottom {
    margin-top: 3px;
    padding: 5px 0;
  }
  &.left {
    margin-left: -3px;
    padding: 0 5px;
  }
}

.tooltip-inner {
  max-width: 200px;
  padding: 3px 8px;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  background-color: #000000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip {
  &.top .tooltip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
  }
  &.top-left .tooltip-arrow {
    bottom: 0;
    left: 5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
  }
  &.top-right .tooltip-arrow {
    bottom: 0;
    right: 5px;
    border-width: 5px 5px 0;
    border-top-color: #000000;
  }
  &.right .tooltip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: #000000;
  }
  &.left .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: #000000;
  }
  &.bottom .tooltip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
  }
  &.bottom-left .tooltip-arrow {
    top: 0;
    left: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
  }
  &.bottom-right .tooltip-arrow {
    top: 0;
    right: 5px;
    border-width: 0 5px 5px;
    border-bottom-color: #000000;
  }
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  background-color: #ffffff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  white-space: normal;
  &.top {
    margin-top: -10px;
  }
  &.right {
    margin-left: 10px;
  }
  &.bottom {
    margin-top: 10px;
  }
  &.left {
    margin-left: -10px;
  }
}

.popover-title {
  margin: 0;
  padding: 8px 14px;
  font-size: 14px;
  font-weight: normal;
  line-height: 18px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover {
  > .arrow {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    &:after {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 10px;
      content: "";
    }
    border-width: 11px;
  }
  &.top > .arrow {
    left: 50%;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #999999;
    border-top-color: rgba(0, 0, 0, 0.25);
    bottom: -11px;
    &:after {
      content: " ";
      bottom: 1px;
      margin-left: -10px;
      border-bottom-width: 0;
      border-top-color: #ffffff;
    }
  }
  &.right > .arrow {
    top: 50%;
    left: -11px;
    margin-top: -11px;
    border-left-width: 0;
    border-right-color: #999999;
    border-right-color: rgba(0, 0, 0, 0.25);
    &:after {
      content: " ";
      left: 1px;
      bottom: -10px;
      border-left-width: 0;
      border-right-color: #ffffff;
    }
  }
  &.bottom > .arrow {
    left: 50%;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #999999;
    border-bottom-color: rgba(0, 0, 0, 0.25);
    top: -11px;
    &:after {
      content: " ";
      top: 1px;
      margin-left: -10px;
      border-top-width: 0;
      border-bottom-color: #ffffff;
    }
  }
  &.left > .arrow {
    top: 50%;
    right: -11px;
    margin-top: -11px;
    border-right-width: 0;
    border-left-color: #999999;
    border-left-color: rgba(0, 0, 0, 0.25);
    &:after {
      content: " ";
      right: 1px;
      border-right-width: 0;
      border-left-color: #ffffff;
      bottom: -10px;
    }
  }
}

.clearfix {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.ic-container {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.ic-container-fluid {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.ic-row {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.ic-form-horizontal .ic-form-group {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.ic-pager {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.panel-body {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.modal-footer {
  &:before, &:after {
    content: " ";
    display: table;
  }
}

.clearfix:after, .ic-container:after, .ic-container-fluid:after, .ic-row:after, .ic-form-horizontal .ic-form-group:after, .ic-pager:after, .panel-body:after, .modal-footer:after {
  clear: both;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
  visibility: hidden !important;
}

.affix {
  position: fixed;
  transform: translate3d(0, 0, 0);
}