//  ======================================================
//  IDLIKETO.SCSS
//  I'd Like to styles
//  ======================================================
#idLikeToModal {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: -100%;
	display: none;
	width: 100%;
	height: 100vh;
	transition: right .3s ease;

	@media (min-width: $screen-sm){
		left: auto;
		width: 420px;
		margin-left: 15px;
	}

	&.open {
		display: block;
		right: 0;
		transition: right .3s ease;
	}

	.modal-body {
		max-height: calc(100vh - 139px);
		overflow: auto;
	}

	.topNavQuicklinks {
		width: 100%;
		margin: 0;
		padding: 0;
		list-style: none;
		text-align: left;

		> li {
			display: flex;
			padding-top: 15px;
			padding-bottom: 15px;
			flex-wrap: wrap;
			border-top: 1px solid #DDDDDD;

			&.hasChildren {
				>span{
					width: calc(100% - 42px);
				}
			}

			>span {
				@extend %text-truncate;
				padding: 13px 0;
				font-size: em(21px);
				font-weight: 600;
				color: #1F1A17;
			}

			.toggle {
				position: relative;
				display: block;
				width: 42px;
				height: 60px;
				font-size: 0;
				cursor: pointer;

				&:before {
					@include icomoon();
					content: $icon-plus;
					position: absolute;
					top: 50%;
					left: 50%;
					font-size: 13px;
					transform: translate(-50%,-50%);
				}
			}

			ul {
				display: none;
				width: calc(100% - 42px);
				margin: 0;
				padding: 0;
				list-style: none;

				a {
					@extend %text-truncate;
					display: block;
					padding: 9px 0 8px;
					font-size: em(16px);
					color: #00737D;
					line-height: 1.2;
					letter-spacing: -.024em;
				}
			}

			&.open {
				.toggle:before {
					content: $icon-minus;
				}

				> ul {
					display: block;
				}
			}
		}
	}
}