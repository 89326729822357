//  ======================================================
//  HEADER.SCSS
//  Styles for HEADER
//  ======================================================

header {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 100%;
	position: relative;
	background-color: #fff;
	z-index: 10;

	@media (max-width: $screen-lg - 1){
		transform: none !important; // override JS embedded style
	}

	@media (min-width: $screen-lg) {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		transition: all $transition-base;
		transition-delay: 0;

		.translated-ltr & {
			top: 40px;
		}

		.scrolling &,
		.hideBanner &,
		.newsPage & {
			box-shadow: 0 8px 12px rgba(black, .08);
		}
		.scrollingDownward & {
			transition-delay: 0.1s;
		}
	}
}

#headerInner {
	display: table;
	table-layout: fixed;
	padding: 10px $container-padding;
	width: 100%;

	@media (min-width: $screen-lg) {
		padding: 0 1em 0 3%;
		width: 100%;
	}
}

/*==================
  HEADER LEFT
====================*/

#headerLeft {
	display: table;
	width: 100%;

	@media (min-width: $screen-lg) {
		position: relative;
		display: table-cell;
		vertical-align: bottom;
		width: 139px;
		max-width: 139px;
		transition: all $transition-base;
	}

	@media (min-width: $screen-xl) {
		width: $header-left-width;
		max-width: $header-left-width;
	}
}


/*==================
  HEADER RIGHT
====================*/

#headerRight {
	display: table-row;

	@media (min-width: $screen-lg) {
		display: table-cell;
		vertical-align: bottom;
		text-align: right;
	}
}


/*==================
  LOGO
====================*/

#logo {
	display: table-cell;
	vertical-align: middle;

	@media (max-width: $screen-lg - 1) {
		height: auto !important;
	}

	@media (min-width: $screen-lg) {
		display: flex;
		align-items: center;
		transition: all $transition-base;
	}

	a {
		display: inline-block;
		vertical-align: middle;
		position: relative;

		@media (min-width: $screen-lg) {
			position: absolute;
			z-index: 1000;
			top: 50%;
			transform: translateY(-50%);
		}

		img {
			display: block;
			width: 64px;
			transform-origin: left center;

			@media (min-width: $screen-lg) {
				width: 124px;
				height: auto;
				transition: transform .3s ease;
			}

			@media (min-width: $screen-xl) {
				width: 184px;

				.scrollingDownward & {
					transform: scale(.6);
				}
			}
		}

		&:focus img {
			outline: 1px solid black;
		}
		&:active img {
			outline: none !important;
		}

		.printLogo {
			display: none;
		}
	}

	h1,
	p {
		margin-top: 0;
		margin-bottom: 0;
		font-size: inherit;
		line-height: 1;
	}
}