$footermega-col-count:					4 !default;
$footermega-col-spacing:				30px !default; // space between colums

//top 'main' links
$footermega-top-item-height: 			null !default;
$footermega-top-item-padding: 			0 0 15px !default;
$footermega-top-item-color: 			null !default;
$footermega-top-item-font-size: 		1.125rem !default;
$footermega-top-item-font-weight: 		$bold-text-font-weight !default;
$footermega-top-item-decoration: 		null !default;
$footermega-top-item-hover-decoration: 	underline !default;

//child links
$footermega-item-padding: 				7px 0 !default;
$footermega-item-color: 				$gray-light !default;
$footermega-item-font-size: 			1rem !default;
$footermega-item-font-weight: 		    $font-weight-base !default;
$footermega-item-decoration: 			null !default;
$footermega-item-hover-decoration: 		underline !default;

.footerNavMega {
    display: none;

    @media (min-width: $screen-md) {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        margin-right: -($footermega-col-spacing/2);
        margin-left: -($footermega-col-spacing/2);
        padding-left: $footermega-col-spacing;
        max-width: 100%;
        overflow: hidden;
    }
}

.footerNavMegaCol {
    flex-grow: 1;
    padding: 0 round($footermega-col-spacing / 2);

    @media (min-width: $screen-md){
        width: abs(100% / $footermega-col-count);
    }

    li {
        display: block;
        padding: $footermega-item-padding;
        height: auto;
        color: $footermega-item-color;
        font-size: $footermega-item-font-size;
        font-weight: $footermega-item-font-weight;
        text-overflow: ellipsis;
        overflow: hidden;

        a {
            font-weight: inherit;
            text-decoration: $footermega-item-decoration;

            &:active,
            &:focus,
            &:hover {
                text-decoration: $footermega-item-hover-decoration;
            }

            &.viewMore {
                font-weight: bold;
            }
        }

        &.topItem {
            padding-top: 0;
            padding-bottom: 0;
            font-size: $footermega-top-item-font-size;
            font-weight: $footermega-top-item-font-weight;
            height: $footermega-top-item-height;
            color: $footermega-top-item-color;

            a {
                &:active,
                &:focus,
                &:hover {
                    text-decoration: $footermega-top-item-hover-decoration;

                    > * {
                        text-decoration: $footermega-top-item-hover-decoration;
                    }
                }
            }

            > h2,
            > h3,
            > h4 {
                margin: 0;
                padding: $footermega-top-item-padding;
                font-size: inherit;
                font-weight: inherit;
                text-overflow: ellipsis;
                color: inherit;
                overflow: hidden;
            }
        }
    }

    ul {
        @extend %resetList;
    }
}